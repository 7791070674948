import React, { useEffect } from 'react'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

import {
  Flex,
  Text,
  Box,
  PrimaryButton,
  FAIcon,
  SecondaryOutlinedButton,
} from '@fivehealth/botero'

import { useNavStateContext } from 'context/NavStateContext'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import DocumentBulkEditModal from './DocumentBulkEditModal'

import { useModal } from '../../../context/ModalContext'
import EventsConstant from '../../../config/constants/events.constants'

const DocumentsRowSelect = ({
  selectedFlatRows,
  toggleAllRowsSelected,
  onCancelRowEditMode,
  enableRowSelect,
  totalCount,
  rowSelectionType,
  filters,
  setRowSelectionType,
}) => {
  const { expanded } = useNavStateContext()
  const { openModal, closeModal } = useModal()

  useEffect(() => {
    if (enableRowSelect && selectedFlatRows.length === 0) {
      toggleAllRowsSelected(rowSelectionType !== 'customSelect')
    }
  }, [rowSelectionType])

  const bulkActions = [
    {
      id: 'edit_document_settings',
      label: 'Edit Document Settings',
      color: 'black',
      onClick: () => {
        openModal(
          <DocumentBulkEditModal
            selectedFlatRows={selectedFlatRows}
            toggleAllRowsSelected={toggleAllRowsSelected}
            onCancelRowEditMode={onCancelRowEditMode}
            rowSelectionType={rowSelectionType}
            closeModal={closeModal}
            filters={filters}
            openModal={openModal}
          />,
          {
            overflow: 'scroll',
          }
        )
      },
      logEventProps: {
        subSource: EventsConstant.DOCUMENTS_BULK_ACTION,
        eventName: EventsConstant.DOCUMENTS_BULK_EDIT,
        page: EventsConstant.DOCUMENTS_PAGE,
      },
    },
  ]

  return (
    <Box
      position="fixed"
      zIndex="1"
      bg="#E9F0FE"
      p={2}
      borderRadius="8px"
      bottom="18px"
      right={`${expanded ? '60px' : '150px'}`}
      left={`${expanded ? '400px' : '200px'}`}
      boxShadow="0px 6px 12px #98a2b326, 0px 4px 4px #98a2b326, 0px 2px 2px #98a2b326"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Text m={1} textAlign="center" color="primary" fontWeight="bold">
            {rowSelectionType === 'selectAll'
              ? totalCount
              : selectedFlatRows.length}{' '}
            document{selectedFlatRows.length > 1 ? 's' : ''} selected
          </Text>
          {rowSelectionType !== 'selectAll' && !_.isEmpty(selectedFlatRows) && (
            <SecondaryOutlinedButton
              borderRadius="8px"
              bg="white"
              onClick={() => {
                toggleAllRowsSelected(false)
              }}
            >
              Deselect
            </SecondaryOutlinedButton>
          )}
        </Flex>
        <Flex>
          <SecondaryOutlinedButton
            mr={2}
            borderRadius="8px"
            bg="white"
            onClick={() => {
              onCancelRowEditMode()
              setRowSelectionType()
            }}
          >
            Cancel
          </SecondaryOutlinedButton>
          <DropdownMenu
            fontWeight="normal"
            actions={bulkActions}
            label={
              <PrimaryButton
                borderRadius="8px"
                mr={2}
                endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
              >
                Bulk actions
              </PrimaryButton>
            }
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default DocumentsRowSelect
