import {
  Box,
  DataTable,
  FAIcon,
  Flex,
  InputField,
  SecondaryOutlinedButton,
  Text,
  useApi,
  useDebounce,
} from '@fivehealth/botero'
import { faSearch, faTimes, faCog } from '@fortawesome/pro-regular-svg-icons'
import _, { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import getCell, { numberComma } from 'Utils'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import Filter from 'views/Filter/Filter'
import SimpleLoader from 'components/SimpleLoader/SimpleLoader'
import UserGroupsTableRowEdit from './UserGroupsTableRowEdit'
import UserGroupsTableRowSelect from './UserGroupsTableRowSelect'
import EventsConstant from '../../../../config/constants/events.constants'
import { onToggleFilterCallback } from '../../../../Utils'

const excludeColumns = ['uid', 'ruleset', 'createdBy']
const sortColumn = ['name', 'createdOn']

const UserGroupsTable = ({
  showEditView,
  showGroupEditView,
  addToFixedGroupFlow = false,
  onChangeSelectedRows,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [filter, setFilter] = useState({})
  const [sortParams, setSortParams] = useState({
    id: 'createdOn',
    desc: true,
  })
  const [isDeleteing, setIsDeleting] = useState(false)

  const [enableRowSelect, setEnableRowSelect] = useState(addToFixedGroupFlow)
  const [rowSelectionType, setRowSelectionType] = useState('') // customSelect, selectAllVisible, selectAll
  const [selectedRowUids, setSelectedRowUids] = useState([])

  const dropdownRef = useRef()
  const debouncedSearchQuery = useDebounce(searchQuery, 600)

  useEffect(() => {
    if (onChangeSelectedRows) {
      onChangeSelectedRows(selectedRowUids)
    }
  }, [selectedRowUids])

  const {
    queries: {
      useProfilesGroupOptimized,
      useAllProfileGroups,
      useEinsteinAdministrators,
    },
  } = useApi({
    queries: [
      'useProfilesGroupOptimized',
      'useAllProfileGroups',
      'useEinsteinAdministrators',
    ],
  })

  const { data: allProfileGroupsResponse } = useAllProfileGroups({
    variables: {},
  })

  const allProfileGroups =
    (allProfileGroupsResponse &&
      allProfileGroupsResponse?.einsteinProfilesGroups?.edges) ||
    []

  const {
    data: profilesGroupData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
  } = useProfilesGroupOptimized({
    variables: {
      sortBy: sortParams.id === 'createdOn' ? 'CREATED_ON' : 'NAME',
      sortDesc: sortParams.desc,
      first: 25,
      ...filter,
    },
  })

  const profilesGroup = _.chain(profilesGroupData)
    .get('pages', [])
    .flatMap((page) => page || [])
    .value()

  const createColumns = useCallback((groups) => {
    if (!_.isEmpty(groups)) {
      const firstGroup = _.first(groups)
      const keys = Object.keys(firstGroup).filter(
        (key) => !_.includes(excludeColumns, key)
      )

      const finalColumns = keys.map((key) => ({
        id: key,
        accessor:
          key === 'Members count' ? 'ruleset.allProfiles.totalCount' : key,
        Header: key === 'description' ? 'Group Description' : _.startCase(key),
        disableSortBy: !_.includes(sortColumn, key),
        Cell: (value) => getCell(key, value.value, null, true),
      }))

      finalColumns.push({
        id: 'createdBy',
        accessor: 'createdBy.fullName',
        Header: _.startCase('Created By'),
        disableSortBy: true,
        Cell: ({ value }) => <Text cursor="pointer">{value}</Text>,
      })

      finalColumns.push({
        id: 'Members count',
        accessor: 'ruleset.allProfiles.totalCount',
        Header: _.startCase('Members'),
        disableSortBy: true,
        Cell: ({ value }) => <Text cursor="pointer">{numberComma(value)}</Text>,
      })

      return finalColumns
    }
    return []
  }, [])

  useEffect(() => {
    if (isDeleteing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      // Cleanup function to ensure proper styles when component unmounts
      document.body.style.overflow = 'auto'
    }
  }, [isDeleteing])

  const handleTableRowClick = (row) => {
    if (
      addToFixedGroupFlow ||
      (dropdownRef.current && !dropdownRef.current.open)
    ) {
      if (enableRowSelect) {
        row.toggleRowSelected()
        const index = selectedRowUids.findIndex(
          (selectedRow) => selectedRow.uid === row.original.uid
        )

        if (index > -1) {
          selectedRowUids.splice(index, 1)
          setSelectedRowUids([...selectedRowUids])
          return
        }

        setSelectedRowUids([...selectedRowUids, row.original])
      } else {
        showEditView(row.original)
      }
    }
  }

  useEffect(() => {
    setFilter({ ...filter, name: debouncedSearchQuery })
  }, [debouncedSearchQuery])

  const getNoResultsProps = () => {
    if (!searchQuery) {
      return {
        avatarProps: { height: '200px', width: '200px' },
        title: 'No user group',
        description: '',
      }
    }

    return { avatarProps: { height: '200px', width: '200px' } }
  }

  const handleCancelBulkUpdate = () => {
    setEnableRowSelect(false)
    setRowSelectionType('')
    setSelectedRowUids([])
  }

  const handleDeleteCallback = (isDeleting) => {
    setIsDeleting(isDeleting)
  }

  const renderTable = () => {
    if (isLoading || isFetching) return <ProgressAnimation />

    return (
      <>
        {isDeleteing && <SimpleLoader />}
        <DataTable
          key={`${enableRowSelect}-${rowSelectionType}`}
          columns={createColumns(profilesGroup)}
          data={profilesGroup}
          hasNextPage={hasNextPage}
          onFetchData={({ sortBy }) => {
            setSortParams(sortBy[0])
          }}
          onFetchNextPage={fetchNextPage}
          initialSortBy={sortParams}
          isFetchingNextPage={isFetchingNextPage}
          noResultsProps={getNoResultsProps()}
          onRowClick={handleTableRowClick}
          disableCheckboxes={rowSelectionType === 'selectAll'}
          enableRowSelect={enableRowSelect}
          enableRowEdit={!addToFixedGroupFlow}
          showRowSelectedBar
          hideHeaderSelectionCheckBox
          autoResetSelectedRows
          renderRowSelectInfo={(props) => (
            <UserGroupsTableRowSelect
              key={enableRowSelect}
              {...props}
              data={
                rowSelectionType === 'selectAll'
                  ? allProfileGroups
                  : profilesGroup
              }
              enableRowSelect={enableRowSelect}
              rowSelectionType={rowSelectionType}
              setIsDeleting={handleDeleteCallback}
              onCancelBulkUpdate={handleCancelBulkUpdate}
              selectedRowUids={selectedRowUids}
              addToFixedGroupFlow={addToFixedGroupFlow}
              setSelectedRowUids={setSelectedRowUids}
            />
          )}
          renderRowEdit={({ original }) =>
            !addToFixedGroupFlow &&
            !enableRowSelect && (
              <UserGroupsTableRowEdit
                setIsDeleting={handleDeleteCallback}
                data={original}
                showEditView={showEditView}
                dropdownRef={dropdownRef}
                showGroupEditView={showGroupEditView}
              />
            )
          }
          logEventProps={{
            subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
            eventName: EventsConstant.USER_GROUP_DETAILS_OPENED,
            page: EventsConstant.USER_GROUP_PAGE,
          }}
        />
      </>
    )
  }

  const actions = [
    {
      id: 'selectAll',
      label: 'Select all groups',
      onClick: () => {
        setEnableRowSelect(true)
        setRowSelectionType('selectAll')
        setSelectedRowUids(allProfileGroups.map((group) => group))
      },
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
        eventName: EventsConstant.USER_GROUP_ACTIONS_SELECT_ALL,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
    {
      id: 'customSelections',
      divider: 'true',
      label: 'Custom selection',
      onClick: () => {
        setEnableRowSelect(true)
        setRowSelectionType('customSelect')
        setSelectedRowUids([])
      },
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
        eventName: EventsConstant.USER_GROUP_ACTIONS_SELECT_CUSTOM,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
  ]

  // Filters
  const [activeFilters, setActiveFilters] = useState({})
  const [showFilters, setShowFilters] = useState(false)
  const [filterOptions, setFilterOptions] = useState([])
  const { data: admins } = useEinsteinAdministrators({
    variables: {},
  })
  useEffect(() => {
    if (admins) {
      const filtersData = admins.edges.map((edge) => ({
        id: edge.node.uid,
        label: edge.node.fullName,
      }))

      const filters = [
        {
          id: 'createdBy',
          title: 'Created By',
          multiSelect: true,
          data: filtersData,
        },
      ]
      setFilterOptions(filters)
    }
  }, [admins])
  const onToggleFilter = ({ nextFilterState }) => {
    onToggleFilterCallback(
      nextFilterState,
      filter,
      activeFilters,
      setActiveFilters
    )
  }
  const onToggleFilters = (open) => {
    setShowFilters(open)
    if (!open) {
      const createdBy = {}
      filter?.createdBy_Uid_In?.forEach((ele) => {
        createdBy[ele] = true
      })
      setActiveFilters({
        createdBy,
        name: filter.name,
      })
    }
  }
  const onResetFilters = () => {
    setActiveFilters(null)
  }

  const onApplyFilters = (filters) => {
    let selectedUserIds = []

    if (filters.createdBy) {
      selectedUserIds = Object.keys(filters.createdBy).filter(
        (ele) => filters.createdBy[ele]
      )
    }

    setShowFilters(false)

    setFilter({
      ...filter,
      createdBy_Uid_In: selectedUserIds.length ? selectedUserIds : null,
    })
  }
  const onFilterCancel = () => {
    setActiveFilters(activeFilters)
  }

  return (
    <Box data-testid="userGroupsTable">
      {/* Search bar */}
      <Flex mb={2} justifyContent="space-between" alignItems="center">
        <Box flex={[0.95, null]}>
          <InputField
            justifyContent="space-between"
            alignItems="center"
            placeholder="Search groups by name"
            startIcon={
              <FAIcon icon={faSearch} fontSize={16} color="fullShade" />
            }
            width={['100%', 500]}
            fontWeight="400"
            height="43.5px"
            lineHeight="24px"
            endIcon={
              !isEmpty(searchQuery) && (
                <FAIcon
                  cursor="pointer"
                  onClick={() => {
                    setSearchQuery('')
                  }}
                  icon={faTimes}
                  fontSize={16}
                  color="fullShade"
                />
              )
            }
            fontSize={[14]}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            disabled={rowSelectionType === 'selectAll'}
          />
        </Box>

        <Flex width="220px" justifyContent="space-between">
          <DropdownMenu
            moveLeft="7px"
            fontWeight="400"
            label={
              <SecondaryOutlinedButton
                display={
                  addToFixedGroupFlow || profilesGroup.length === 0
                    ? 'none'
                    : 'flex'
                }
                endIcon={
                  <FAIcon icon={faCog} fontSize={14} color="darkestShade" />
                }
                color="darkestShade"
                borderRadius="8px"
                logEventProps={{
                  subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
                  eventName: EventsConstant.USER_GROUP_ACTIONS_OPENED,
                  page: EventsConstant.USER_GROUP_PAGE,
                }}
              >
                Actions
              </SecondaryOutlinedButton>
            }
            actions={actions}
            width="200px"
          />
          <Filter
            testId="filterBroadcastButton"
            activeFilters={activeFilters}
            minWidth={550}
            filterOptions={filterOptions}
            onFilterChange={onToggleFilter}
            open={showFilters}
            onOpen={onToggleFilters}
            onResetFilters={onResetFilters}
            onSave={onApplyFilters}
            onCancel={onFilterCancel}
            logEventPropsApply={{
              subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
              eventName: EventsConstant.USER_GROUP_FILTERS_APPLIED,
              page: EventsConstant.USER_GROUP_PAGE,
            }}
            logEventPropsCancel={{
              subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
              eventName: EventsConstant.USER_GROUP_FILTERS_CENCELED,
              page: EventsConstant.USER_GROUP_PAGE,
            }}
            logEventPropsOpened={{
              subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
              eventName: EventsConstant.USER_GROUP_FILTERS_OPENED,
              page: EventsConstant.USER_GROUP_PAGE,
            }}
            logEventPropsReset={{
              subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
              eventName: EventsConstant.USER_GROUP_FILTERS_RESET,
              page: EventsConstant.USER_GROUP_PAGE,
            }}
          />
        </Flex>
      </Flex>

      {/* Table */}
      {renderTable()}
    </Box>
  )
}

export default UserGroupsTable
