import React, { useEffect, useState } from 'react'
import {
  Flex,
  Box,
  Body,
  H1,
  H3,
  H4,
  Input,
  PrimaryButton,
  Text,
  FAIcon,
} from '@fivehealth/botero'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useHistory } from 'react-router-dom'
import posthog from 'posthog-js'
import Config from '../../Config'
import Logo from '../../assets/botmd_logo.svg'
import Bg from '../../assets/Bg.png'
import EventsConstant from '../../config/constants/events.constants'

function InformationSection() {
  return (
    <Box display={['none', 'none', 'block']} flex={1}>
      <div
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Flex
          minHeight="100vh"
          p={9}
          pb={6}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Flex flexDirection="column" maxWidth="350px">
            <H3 fontSize="24px" lineHeight="24px" color="white" mb={3}>
              Welcome to Einstein
            </H3>
            <Body color="white" fontSize="16px" lineHeight="24px">
              Einstein is designed for hospital administrators to be able to
              easily view and manage their Bot MD Hospital users and easily sync
              hospital content and dialogues to the Bot.
              <br />
              <br />
              Contact{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="mailto:sales@botmd.io?subject=Einstein"
                target="_blank"
                rel="noopener noreferrer"
              >
                sales@botmd.io
              </a>{' '}
              if you are interested to learn more.
            </Body>
          </Flex>
        </Flex>
      </div>
    </Box>
  )
}

function Home({ setOrgKey }) {
  const history = useHistory()
  const location = useLocation()
  const [org, setOrg] = useState({ value: '', error: '' })
  const [keyValue, setKeyValue] = useState('')

  useEffect(() => {
    if (location.state) {
      setOrg({
        value: org.value ? org.value : '',
        error: location.state.error,
      })
    }
  }, [location])

  useEffect(() => {
    if (keyValue) {
      setTimeout(() => history.push(`/login/${org.value}`, 100))
    }
  }, [keyValue])

  const handleOrgSubmit = () => {
    if (!Config.ORGANIZATIONS.includes(org.value.toLowerCase())) {
      setOrg({
        value: org.value,
        error: 'We could not find your organization',
      })
    } else {
      localStorage.setItem('orgKey', org.value)
      setOrgKey(org.value)
      setKeyValue(org.value)
    }
  }
  return (
    <Flex minHeight="100vh" flexDirection={['column', 'column', 'row']}>
      <Flex p={[0, 8]} flex={1} width="100%">
        <Box width="100%">
          <Flex
            flex={1}
            height="100%"
            p={['10px', 0]}
            maxWidth="600px"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            alignItems="start"
            margin="auto"
          >
            <Box
              textAlign={['center', 'left']}
              mt={['5px', 0]}
              marginBottom="auto"
              width={['calc(100% - 10px)', '100%']}
              p={['10px', 0]}
              alignItems={['center', '1']}
              borderBottom={['1px solid #DDE1E6', 'inherit']}
              borderTop={['1px solid #DDE1E6', 'inherit']}
            >
              <Box as="img" src={Logo} />
            </Box>
            <H1 mb="60px">Log in to your organization</H1>
            <Flex alignItems="start">
              <H4 mt="18px"> {`https://einstein.${Config.domain}/`}</H4>
              <Box>
                <Input
                  pr={['10px', 0]}
                  data-testid="organizationInput"
                  position="absolute"
                  labelProps={{ style: { display: 'none' } }}
                  ml={1}
                  height="48px"
                  maxWidth="235px"
                  value={org.value}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleOrgSubmit()
                      posthog.capture('auth:login_button_click', {
                        organization_key: org.value,
                      })
                    }
                  }}
                  onChange={(e) =>
                    setOrg({ value: e.currentTarget.value, error: '' })
                  }
                  placeholder="your organization url"
                />
              </Box>
            </Flex>
            {org.error && org.error.length > 0 && (
              <Flex
                bg="#F9DCD7"
                height="46px"
                mt={3}
                width="350px"
                borderRadius="8px"
                alignItems="center"
              >
                <Flex pl="16px" alignItems="center">
                  <FAIcon
                    icon={faExclamationCircle}
                    style={{
                      fontSize: 20,
                      fontWeight: 300,
                      color: '#E05138',
                    }}
                  />
                </Flex>
                <Text
                  p={1}
                  mt="2px"
                  ml="8px"
                  borderRadius={4}
                  fontSize="14px"
                  fontWeight="400"
                  textAlign="center"
                  color="#E05138"
                  style={{ boxSizing: 'content-box' }}
                >
                  {`${org.error} `}
                  {org.error === 'Error logging in.' && (
                    <a
                      style={{
                        textDecoration: 'underline',
                        color: '#256BF6',
                      }}
                      href="mailto:customersupport@botmd.io"
                    >
                      Contact us
                    </a>
                  )}
                  {org.error === 'Error logging in.' && ' for support'}
                </Text>
              </Flex>
            )}
            <PrimaryButton
              maxWidth="400px"
              width="100%"
              borderRadius="8px"
              height="48px"
              mt="67px"
              onClick={() => {
                handleOrgSubmit()
                posthog.capture('auth:login_button_click', {
                  organization_key: org.value,
                })
              }}
              data-testid="loginButton"
              logEventProps={{
                eventName: EventsConstant.LOGIN_ATTEMPT,
                subSource: EventsConstant.LOGIN_SOURCE,
                page: EventsConstant.HOME_PAGE,
              }}
            >
              <Text color="white" fontWeight="600" fonstSize="16px">
                Log In
              </Text>
            </PrimaryButton>
            <Body
              marginTop="auto"
              textAlign="center"
              maxWidth="400px"
              color="#A3A9B1"
              extraSmall
            >
              By registering, you agree to the Terms & Conditions of Use of the
              Service as well as the Bot MD Privacy Policy
            </Body>
          </Flex>
        </Box>
      </Flex>
      <InformationSection />
    </Flex>
  )
}

export default Home
