const IS_PRODUCTION = () =>
  !/staging|ngrok|localhost|192.168.1.57/i.test(window.location.href)
const IS_UAT_ENV = () => /uat.staging/i.test(window.location.href)

const REDIRECT_PATH = '/auth/callback'

let environment
if (IS_PRODUCTION()) {
  environment = 'production'
} else if (IS_UAT_ENV()) {
  environment = 'uat'
} else {
  environment = 'staging'
}
let topLevelDomain = ''
if (window.location.hostname === 'localhost') {
  topLevelDomain = 'localhost'
} else {
  const [, tld] = window.location.hostname.match(/(\.[a-z]{2,})$/i) || []
  topLevelDomain = tld
}

const domain = topLevelDomain === '.sg' ? 'botmd.sg' : 'botmd.io'

const BEACON_WIDGET_KEY = '753b45e0-b064-439b-a557-2588fd7a3543'

// eslint-disable-next-line no-nested-ternary

const numOfHours = 3
const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 60 * numOfHours

const ORGANIZATIONS =
  topLevelDomain === '.sg'
    ? ['cgh', 'ttsh', 'sgh', 'nccs', 'testcgh']
    : [
        'cgh',
        'ktph',
        'ttsh',
        'roche',
        'digihealth',
        'digihealthid',
        'idi',
        'iskandar',
        'nationalhospital',
        'pjnhk',
        'botmd',
        'nkti',
        'sanglah',
        'ncis',
        'ncis_engage_web',
        'Parkway radio',
        'roche_hcp',
        'roche_hcp_private',
        'dharmais',
        'idi_web',
        'idi_whatsapp',
        'sgh',
        'nuhs',
        'nuh',
        'nemg_web',
        'fleminghospital',
        'parkwayradio',
        'siloam',
        'konsultamd',
        'upcm',
        'faithful_empire_web',
        'nccs',
        'testcgh',
      ]
if (!IS_PRODUCTION()) {
  ORGANIZATIONS.push('testktph')
}
const AMPLITUDE_KEY = IS_PRODUCTION()
  ? 'd0a1bce7491910c844f4b73517ab3d26'
  : 'eadbc909d7ae8cbbaa1bbecf2ae17353'

const POSTHOG_API_KEY = IS_PRODUCTION()
  ? 'phc_8lezRbjEAigtaLFd5YV07nJFwNcIBnaaZ5yjzMXQYHb' // production api key
  : 'phc_WODstk2GAh0OZbvhdpAXWjcx8PH7hK8S2A7nokmSSP8' // staging api key
const POSTHOG_HOST = 'https://us.i.posthog.com'

export default {
  env: process.env.NODE_ENV,
  environment,
  IS_PRODUCTION,
  IS_UAT_ENV,
  cookie: { name: 'einstein:session', maxAge: 2595599 }, // 30 days,
  REDIRECT_PATH,
  BEACON_WIDGET_KEY,
  ORGANIZATIONS,
  DEFAULT_QUERY_STALE_TIME,
  AMPLITUDE_KEY,
  domain,
  POSTHOG_API_KEY,
  POSTHOG_HOST,
}
