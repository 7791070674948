/* eslint-disable no-return-assign */

import {
  Box,
  CalendarWithTime,
  FAIcon,
  Flex,
  InputField,
  Label,
  PhoneInputField,
  Radio,
  Select,
  Text,
  useApi,
  CreatableSelect,
  EditableSelect,
} from '@fivehealth/botero'
import { faTimes, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import Input from 'components/Input/Input'
import {
  includes,
  map,
  chain,
  isEmpty,
  get,
  isNull,
  constant,
  times,
  zipObject,
  isEqual,
  startCase,
} from 'lodash'
import ReminderPicker from 'components/Reminder/ReminderPicker'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { isAfter, startOfMonth, startOfToday, startOfYear } from 'date-fns'
// import { useHistory } from 'react-router-dom'
import {
  ReminderFrequencyUnitOptions,
  InputTitle,
  checkValidDate,
  capitalizeFirstLetter,
  getFormattedISODate,
  isPhoneValid,
  validateEmail,
  validName,
} from 'Utils'
import styled, { css } from 'styled-components'
import BotSelect from 'components/BotSelect/BotSelect'

const PreviewImage = styled.img`
  height: 120px;
  margin-top: 8px;
  border: 1px solid #d5d7de;
  border-radius: 8px;
`

const FieldContainer = styled(Box)`
  ${(props) =>
    props.hasError
      ? css`
          & input.bot-input,
          & div.bot-button,
          & div.target-border {
            border: 1px solid rgb(224, 81, 56) !important;
          }
        `
      : css``}
`

const Form = ({
  formRef,
  forms,
  startIcon,
  fieldPerRow = 1,
  onFormChange,
  defaultFormData = {},
  formSpacing = true,
  labelSize = '14px',
  withinOutlinedBox = false,
  disabledDeactivatedAfter = false,
  isQuickEdit = false,
  scrollToError = true,
}) => {
  const hiddenFileInput = useRef()
  const calendarRef = useRef(null)
  const [formData, setFormData] = useState(defaultFormData)
  const [uploadedData, setUploadedData] = useState({ uploadID: '' })
  const [formDataError, setFormDataError] = useState({})
  const [selected, setSelected] = useState('')
  const [showSchedule, setShowSchedule] = useState(false)
  const [fileOverSizeError, setFileOverSizeError] = useState(false)

  const [showRecurringBroadcast, setShowRecurringBroadcast] = useState(false)
  useEffect(() => {
    if (defaultFormData && Object.keys(defaultFormData).length) {
      setFormData({ ...(formData || {}), ...defaultFormData })
    }
  }, [defaultFormData])
  const fieldRefs = useRef(Object.create(null))

  useEffect(() => {
    setTimeout(() => {
      const errorFields = Object.keys(formDataError)
      if (errorFields.length > 0) {
        const lastErrorFieldId = errorFields?.[errorFields.length - 1]
        const lastErrorFieldRef = fieldRefs?.current?.[lastErrorFieldId]
        if (lastErrorFieldRef && scrollToError) {
          lastErrorFieldRef.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, 300)
  }, [formDataError])

  const today = new Date()
  today.setHours(9, 0, 0, 0) // set default as 9am for consistency
  const [date, setDate] = useState(today)

  const [selectedFrequency, setSelectedFrequency] = useState('')
  // const history = useHistory()
  const [recurringBroadcastDate, setRecurringBroadcastDate] = useState()
  const [recurringBroadcastTime, setRecurringBroadcastTime] = useState({})
  const [defaultRecurringBroadcastTime, setDefaultRecurringBroadcastTime] =
    useState('09:00')
  const [broadcastStartAndEndDate, setBroadcastStartAndEndDate] = useState({
    startDate: '',
    endDate: '',
  })

  const [uploadFile, setUploadFile] = useState(null)
  const {
    queries: { useStitchUpload },
  } = useApi({
    queries: ['useStitchUpload'],
  })

  const { mutateAsync: uploadFileToStitch } = useStitchUpload({
    variables: {},
    onSuccess: () => {},
  })

  const checkFileSize = (size, limit = 5) =>
    chain(size)
      .divide(1024) // KB
      .divide(1024) // MB
      .inRange(limit) // default 5MB
      .value()

  const checkValid = (statusText, formDefinition = null) => {
    if (fileOverSizeError) {
      return false
    }
    const fieldWithValue = chain(formData)
      .map((value, key) => {
        if (key === 'deactivateAfter') {
          if (disabledDeactivatedAfter) {
            return key
          }
          const deactivateDate = new Date(value)
          const currentDate = new Date()
          if (deactivateDate <= currentDate) {
            return null
          }
          return key
        }
        if (key === 'activateAfter') {
          const selectedDate = new Date(value)
          const currentDate = new Date()
          if (selectedDate <= currentDate) {
            return null
          }
          return key
        }
        if (key === 'email') {
          if (!validateEmail(value)) {
            return null
          }
          return key
        }
        if (['firstName', 'lastName'].includes(key)) {
          if (validName(value)) {
            return key
          }
          return null
        }
        if (key === 'recipients' && !value?.length) {
          return null
        }
        if (key === 'department' || key === 'designation') {
          if (value?.value || value?.label) {
            return key
          }
          return null
        }
        if (key === 'selectedDepartments') {
          return key
        }

        return !value || (key === 'phone' && !isPhoneValid(value)) ? null : key
      })
      .filter((key) => !isNull(key))
      .value()

    let missingField = chain(formDefinition || forms)
      .flatMap((form) => form.fields)
      .filter(
        (field) =>
          get(field, 'required', false) ||
          (field.id === 'deactivateAfter' && formData.deactivateAfter) ||
          (field.id === 'activateAfter' && formData.activateAfter)
      )
      .map((field) => field.id)
      .difference(fieldWithValue)
      .value()

    if (statusText === 'DRAFT') {
      if (!includes(fieldWithValue, 'broadcastName')) {
        missingField = ['broadcastName']
        setFormDataError(
          zipObject(
            missingField,
            times(missingField.length, constant('Required Field'))
          )
        )
        return false
      }
      return true
    }

    setFormDataError(
      zipObject(
        missingField,
        times(missingField.length, constant('Required Field'))
      )
    )

    const scheduledFor = get(formData, 'scheduledFor', '')

    if (
      !isEmpty(scheduledFor) &&
      formData.scheduleMessage === 'scheduleLater'
    ) {
      const calendarField = chain(formDefinition || forms)
        .flatMap((form) => form.fields)
        .filter((field) => field.id === 'scheduleMessage')
        .map((field) => field.id)
        .value()

      if (
        !DateTime.fromISO(scheduledFor).invalid &&
        formData.scheduleMessage === 'scheduleLater'
      ) {
        if (
          DateTime.now().setLocale('en-SG').toFormat('dd LLL yyyy, t') >
          DateTime.fromISO(scheduledFor)
            .setLocale('en-SG')
            .toFormat('dd LLL yyyy, t')
        ) {
          setFormDataError(
            zipObject(
              calendarField,
              times(
                calendarField.length,
                constant('Scheduled date is in the past')
              )
            )
          )
          return false
        }
      } else if (
        DateTime.now().toISO() >
          DateTime.fromJSDate(scheduledFor).toUTC().toISO() &&
        formData.scheduleMessage === 'scheduleLater'
      ) {
        setFormDataError(
          zipObject(
            calendarField,
            times(calendarField.length, constant('Date is Invalid'))
          )
        )
        return false
      }
    }
    if (!isEmpty(selectedFrequency)) {
      if (
        showRecurringBroadcast &&
        (selectedFrequency === 'week' || selectedFrequency === 'month') &&
        isEmpty(recurringBroadcastDate)
      ) {
        return false
      }
    }

    if (isEmpty(missingField)) {
      return true
    }

    return false
  }

  const updateRecurringSelectedDate = (key, value) => {
    setRecurringBroadcastDate({ key, value })
  }

  const updateFormData = (id, value) => {
    if (id === 'url_upload' && value.startsWith('data:')) {
      setFormData({
        ...formData,
        [id]: value,
        file_upload: uploadedData.uploadID,
        url_with_key: uploadedData.urlWithKey,
      })
    } else if (id === 'websiteURL') {
      setFormData({
        ...formData,
        [id]:
          value === 'h' || !isEmpty(value.match(`^(?:h(t)(t)?(p)?(s)?)`))
            ? value
            : `https://${value}`,
      })
    } else if (id === 'selectedDocument') {
      setFormData({ ...formData, [id]: value, clearDefaultDocument: true })
    } else {
      setFormData({ ...formData, [id]: value })
    }

    if (
      id === 'scheduledFor' &&
      formData?.scheduleMessage === 'scheduleLater'
    ) {
      let datetimeString = ''
      if (!isEmpty(value)) {
        datetimeString = DateTime.fromISO(value)
          .setLocale('en-SG')
          .toFormat('dd LLL yyyy, t')
        setDate(new Date(datetimeString))
        setShowSchedule(true)
      } else if (formData?.scheduleMessage === 'scheduleLater') {
        setShowSchedule(true)
        setDate(today)
      }
      return
    }

    if (id === 'selectedFrequency') {
      setSelectedFrequency({
        label: `${capitalizeFirstLetter(value)}(s)`,
        value,
      })
      return
    }
    if (id === 'recurringStartDate') {
      setBroadcastStartAndEndDate({
        startDate: new Date(value),
      })
      return
    }
    if (id === 'recurringStartandEndDate') {
      setBroadcastStartAndEndDate({
        startDate: new Date(value.startDate),
        endDate: new Date(value.endDate),
      })
      return
    }
    if (id === 'recurringBroadcastTime') {
      setDefaultRecurringBroadcastTime(value)
      return
    }
    if (id === 'reccuringBroadcastWeek') {
      const key = 'reminderFrequencyDaysOfWeek'
      updateRecurringSelectedDate(
        key,
        map(value, (val) => ({ id: val, label: capitalizeFirstLetter(val) }))
      )
      return
    }
    if (id === 'reccuringBroadcastMonth') {
      const key = 'reminderFrequencyDaysOfMonth'
      updateRecurringSelectedDate(
        key,
        map(value, (val) => ({ id: val, value: val, label: `Day ${val}` }))
      )
      return
    }
    if (id === 'scheduleMessage' && value === 'sendNow') {
      setFormData({
        ...formData,
        scheduledFor: '',
        scheduleMessage: 'sendNow',
      })

      setShowSchedule(false)
      setShowRecurringBroadcast(false)
      setBroadcastStartAndEndDate({
        startDate: '',
        endDate: '',
      })
      setSelectedFrequency('')
      return
    }
    if (id === 'scheduleMessage' && value === 'scheduleLater') {
      setFormData({
        ...formData,
        scheduledFor: today,
        scheduleMessage: 'scheduleLater',
      })
      if (defaultFormData.defaultDate) {
        setDate(today)
      }
      setShowSchedule(true)
      setShowRecurringBroadcast(false)
      setSelectedFrequency('')
      setBroadcastStartAndEndDate({
        startDate: '',
        endDate: '',
      })
      return
    }
    if (id === 'scheduleMessage' && value === 'recurringBroadcast') {
      setShowRecurringBroadcast(true)
      setShowSchedule(false)
      return
    }
    if (
      id === 'scheduleMessage' &&
      value !== 'scheduleLater' &&
      value !== 'recurringBroadcast'
    ) {
      setShowSchedule(false)
      setShowRecurringBroadcast(false)
      return
    }
    if (formDataError[id]) {
      delete formDataError[id]
      setFormDataError(formDataError)
    }
  }

  const getFormData = (
    shouldCheckValid = false,
    statusText = null,
    formDefinition = null
  ) => {
    if (shouldCheckValid && checkValid(statusText, formDefinition)) {
      return formData
    }
    if (!shouldCheckValid) {
      return formData
    }
    return null
  }

  const resetFormData = (isButton) => {
    if (!isButton) {
      delete formData.title
      delete formData.message
      delete formData.url_upload
      delete formData.image_source
      delete formData.file_upload
    }
    delete formData.buttonText
    delete formData.websiteURL
    onFormChange(formData)
  }

  const getRawFormData = () => formData

  useEffect(() => {
    if (formRef) {
      formRef({
        formData,
        getFormData,
        updateFormData,
        getRawFormData,
        resetFormData,
        setFormDataError,
      })
    }
    if (formRef && onFormChange && formData) {
      onFormChange(formData)
    }
  }, [formData])

  useEffect(() => {
    if (uploadFile) {
      const reader = new FileReader()
      reader.readAsDataURL(uploadFile)
      reader.onload = () => {
        updateFormData('url_upload', reader.result)
      }
    }
  }, [uploadedData])

  useEffect(() => {
    if (uploadFile && uploadFile !== null) {
      uploadFileToStitch({
        input: {
          key: 'einstein',
          mimeType: uploadFile.type,
        },
      }).then(({ stitchCreateUploadUrl }) => {
        const uploadUrl = stitchCreateUploadUrl.url
          ? stitchCreateUploadUrl.url
          : ''
        const uploadKey =
          stitchCreateUploadUrl.fields && stitchCreateUploadUrl.fields.key
            ? stitchCreateUploadUrl.fields.key
            : ''
        const uploadUrlWithKey = `${uploadUrl}${uploadKey}`
        const body = new FormData()
        const uploadedFile = uploadFile
        setUploadedData({
          ...uploadedData,
          urlWithKey: uploadUrlWithKey,
          uploadID: stitchCreateUploadUrl.uploadId,
        })
        map(stitchCreateUploadUrl.fields, (value, key) => {
          body.append(key, value)
        })
        body.append('file', uploadedFile)

        return fetch(stitchCreateUploadUrl.url, {
          method: 'post',
          body,
        })
      })
    }
  }, [uploadFile])

  const acceptedFileTypes = ['audio/mpeg', 'video/mp4']

  const onDrop = useCallback((acceptedFiles) => {
    setFileOverSizeError(false)
    if (acceptedFiles.length === 1) {
      // 50 mb for the size limit of audio/video
      if (
        acceptedFileTypes.includes(acceptedFiles[0].type) &&
        checkFileSize(acceptedFiles[0].size, 50)
      ) {
        setUploadFile(acceptedFiles[0])
      } else if (!checkFileSize(acceptedFiles[0].size)) {
        setFileOverSizeError(true)
        setUploadFile(null)
      } else {
        setUploadFile(acceptedFiles[0])
      }
    } else {
      setUploadFile(null)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const style = { display: 'block', height: '50px' }

  const updateStartAndEndDate = (key, value) => {
    if (key === 'startDate') {
      setBroadcastStartAndEndDate({
        startDate: value,
        endDate: broadcastStartAndEndDate.endDate,
      })
    } else {
      setBroadcastStartAndEndDate({
        endDate: value,
        startDate: broadcastStartAndEndDate.startDate,
      })
    }
  }

  const updateRecurringSelectedTime = (value) => {
    setRecurringBroadcastTime({ value })
  }

  useEffect(() => {
    // This function will be called when a click event occurs on the document
    const handleClickOutside = (event) => {
      // Check if the click occurred outside the calendar element
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        // Your logic to hide the calendar goes here
        setSelected(false)
      }
    }

    // Add the event listener to the document
    document.addEventListener('mousedown', handleClickOutside)

    // Clean up by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    updateFormData('recurringBroadcast', {
      recurringBroadcastDate,
      recurringBroadcastTime,
      selectedFrequency,
      broadcastStartAndEndDate,
    })
  }, [recurringBroadcastDate, recurringBroadcastTime, broadcastStartAndEndDate])

  const renderScheduleBroadcastFields = (field, noMarginTop = false) => (
    <>
      {map(field.options, (option) => (
        <Box key={`${option.id}-schedule-fields`}>
          {showSchedule && option.id === 'scheduleLater' && (
            <Box mt={2} style={{ position: 'relative' }}>
              {selected && (
                <Box
                  ref={calendarRef}
                  style={{
                    position: 'absolute',
                    top: 74,
                    left: 2,
                    zIndex: 1,
                  }}
                >
                  <CalendarWithTime
                    defaultDate={date}
                    checkValidDay={(d) => checkValidDate(d, startOfToday())}
                    checkValidMonth={(d) =>
                      isAfter(startOfMonth(startOfToday()), startOfMonth(d))
                    }
                    checkValidYear={(d) =>
                      isAfter(startOfYear(startOfToday()), startOfYear(d))
                    }
                    onApply={(day) => {
                      setDate(day)
                      setSelected(false)
                      updateFormData('scheduledFor', day)
                    }}
                    onCancel={() => {
                      setSelected(false)
                    }}
                    withActions
                    validateTime
                  />
                </Box>
              )}
              <Box>
                <Label
                  fontWeight="700"
                  color="#697481"
                  fontSize={labelSize}
                  mt={2}
                  mb={2}
                >
                  Broadcast date
                </Label>
                <InputField
                  height="46px"
                  label="Broadcast date"
                  placeholder="Pick date & time"
                  value={
                    formData.scheduledFor
                      ? DateTime.fromISO(
                          new Date(formData.scheduledFor).toISOString()
                        )
                          .setLocale('en-SG')
                          .toFormat('dd LLL yyyy, t')
                      : DateTime.fromISO(new Date(date).toISOString())
                          .setLocale('en-SG')
                          .toFormat('dd LLL yyyy, t')
                  }
                  inputLimit={field.limit}
                  endIcon={<FAIcon icon={faChevronDown} fontSize={14} />}
                  as={field.as}
                  onClick={() => {
                    if (selected) {
                      setSelected('')
                    } else {
                      setSelected(field.id)
                    }
                    if (option.optionCallback) {
                      option.optionCallback()
                    }
                  }}
                  readOnly
                />
              </Box>
            </Box>
          )}
          {showRecurringBroadcast && option.id === 'recurringBroadcast' && (
            <Box>
              <InputTitle mt={!noMarginTop ? 2 : undefined}>
                Schedule Broadcast every
              </InputTitle>
              <Select
                mt={2}
                maxMenuHeight={180}
                options={ReminderFrequencyUnitOptions}
                value={selectedFrequency}
                onChange={(opt) => {
                  updateRecurringSelectedDate('', '')
                  setSelectedFrequency(opt)
                }}
              />
            </Box>
          )}
          {showRecurringBroadcast &&
            selectedFrequency.value &&
            option.id === 'recurringBroadcast' && (
              <Box>
                <ReminderPicker
                  currentState={recurringBroadcastDate}
                  startAndEndDate={broadcastStartAndEndDate}
                  updateDate={updateRecurringSelectedDate}
                  updateStartDate={updateStartAndEndDate}
                  updateEndDate={updateStartAndEndDate}
                  updateTime={updateRecurringSelectedTime}
                  defaultTime={defaultRecurringBroadcastTime}
                  mt={!noMarginTop ? 2 : undefined}
                  selectedComponent={selectedFrequency.value}
                />
              </Box>
            )}
        </Box>
      ))}
    </>
  )

  const getCalendarDefaultDate = (fieldId) => {
    const defaultDate = get(formData, fieldId, '')
    return defaultDate ? new Date(defaultDate) : date
  }

  const getCalendarValue = (fieldId) => {
    const calendarValue = get(formData, fieldId, '')
    return calendarValue
      ? DateTime.fromISO(new Date(calendarValue).toISOString())
          .setLocale('en-SG')
          .toFormat('dd LLL yyyy, t')
      : ''
  }

  const getComponent = (field, noMarginTop = false) => {
    switch (field.type) {
      case 'options':
        return (
          <Box key={field.id}>
            <div ref={(ref) => (fieldRefs.current[field.id] = ref)} />
            {!!field.label && (
              <Text
                fontSize={labelSize}
                mt={!noMarginTop ? 2 : undefined}
                fontWeight="bold"
                color="darkestShade"
              >
                {field.label}
              </Text>
            )}
            {map(field.options, (option) => (
              <Box key={option.id}>
                <Label
                  mt={!noMarginTop ? 2 : undefined}
                  key={option.id}
                  mr={2}
                  m={1}
                >
                  <Radio
                    checked={isEqual(option.id, get(formData, field.id, ''))}
                    onChange={() => {
                      updateFormData(field.id, option.id)

                      if (
                        [
                          'scheduleLater',
                          'recurringBroadcast',
                          'sendNow',
                        ].includes(option.id) &&
                        formDataError
                      ) {
                        delete formDataError.scheduleMessage
                        setFormDataError(formDataError)
                      }
                    }}
                  />
                  <Text fontSize={14}>{option.label}</Text>
                </Label>
              </Box>
            ))}
            {field.options.some((option) =>
              ['scheduleLater', 'recurringBroadcast'].includes(option.id)
            ) && renderScheduleBroadcastFields(field, noMarginTop)}
          </Box>
        )
      case 'input':
        return (
          <>
            <div ref={(ref) => (fieldRefs.current[field.id] = ref)} />
            <Input
              inputStyles={{ height: '45px' }}
              key={field.id}
              pr={formSpacing ? '8px' : 0}
              labelSize={labelSize}
              display={field.visibility ? 'box' : 'none'}
              label={field.label}
              maxWidth={field.maxWidth ? field.maxWidth : '100%'}
              fontSize="14px"
              disabled={!get(field, 'editable', true)}
              labelRight={field.labelRight}
              placeholder={field.placeholder}
              value={get(formData, field.id, '')}
              error={get(formDataError, field.id, '')}
              inputLimit={field.limit}
              as={field.as}
              onChange={(e) => updateFormData(field.id, e.target.value)}
              noMarginTop={noMarginTop}
            />
          </>
        )
      case 'fileInput': {
        const url = formData.url_upload
          ? formData.url_upload
          : get(formData, field.id, '')

        return (
          <Box
            mb={2}
            mt={field.marginTop ? field.marginTop : 0}
            display={field.visibility ? 'box' : 'none'}
            width="100%"
            {...getRootProps()}
          >
            <div ref={(ref) => (fieldRefs.current[field.id] = ref)} />
            {field.hideUpload ? null : (
              <>
                <Text
                  mb={1}
                  fontSize={labelSize || 1}
                  fontWeight="bold"
                  color="darkestShade"
                >
                  {field.label}
                </Text>
                <Flex alignItems="center" justifyContent="center">
                  {formData.default_url_upload ? (
                    <>
                      <InputField
                        mt="4px"
                        width="100%"
                        disabled
                        value={formData.default_url_upload}
                        style={{ resize: 'none' }}
                      />
                      <Box ml={3} mr={1}>
                        <FAIcon
                          icon={faTimes}
                          fontSize={16}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const newFormData = { ...formData }

                            delete newFormData.default_url_upload

                            setFormData(newFormData)
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <InputField
                      type="file"
                      width="100%"
                      height="100px"
                      {...getInputProps({ style })}
                      innerRef={hiddenFileInput}
                      accept={field.acceptFileType}
                    />
                  )}
                </Flex>
                {!!field.helpText && (
                  <Text
                    mt="4px"
                    color="darkestShade"
                    fontSize="12px"
                    fontWeight="500"
                  >
                    {field.helpText}
                  </Text>
                )}
              </>
            )}

            {field.showPreview && (
              <>
                {field.hideUpload ? null : (
                  <Text
                    mt="4px"
                    color="darkestShade"
                    fontSize="12px"
                    fontWeight="500"
                  >
                    We recommend uploading your logo on a white background, with
                    a 2:1 ratio. Recommended image size 240 x 120 px. Allowed
                    file type: JPG, JPEG, PNG. Maximum 5mb.
                  </Text>
                )}
                {url && (
                  <>
                    <Text
                      mt={2}
                      color="darkestShade"
                      fontSize="12px"
                      fontWeight="600"
                    >
                      Logo preview
                    </Text>
                    <PreviewImage src={url} alt="preview_image" />{' '}
                  </>
                )}
              </>
            )}
            {fileOverSizeError && (
              <Box mt={field.limit ? '-18px' : undefined}>
                <ErrorMessage error="Maximum of 5mb only is allowed for images" />
              </Box>
            )}
          </Box>
        )
      }
      case 'phone':
        return (
          <Box mt={!noMarginTop ? 2 : undefined} mb={1}>
            <div ref={(ref) => (fieldRefs.current[field.id] = ref)} />
            <Text
              mb={1}
              fontSize={labelSize || 1}
              fontWeight="bold"
              color="darkestShade"
            >
              {field.label}
            </Text>
            <FieldContainer
              hasError={!!get(formDataError, field.id, '')}
              display={get(field, 'editable', true) ? 'box' : 'none'}
            >
              <PhoneInputField
                country="sg"
                display={get(field, 'editable', true) ? 'box' : 'none'}
                disabled={!get(field, 'editable', true)}
                value={
                  get(field, 'editable', true)
                    ? get(formData, field.id, '')
                    : ''
                }
                inputStyle={{ width: formSpacing ? '300px' : '100%' }}
                onChange={(value) => updateFormData(field.id, `+${value}`)}
              />
            </FieldContainer>
            <Input
              key={field.id}
              pr={formSpacing ? '8px' : 0}
              display={!get(field, 'editable', true) ? 'box' : 'none'}
              fontSize="14px"
              mt="-16px"
              disabled={!get(field, 'editable', true)}
              labelRight={field.labelRight}
              placeholder={field.placeholder}
              value={get(formData, field.id, '')}
              inputLimit={field.limit}
              as={field.as}
              onChange={(e) => updateFormData(field.id, e.target.value)}
            />
          </Box>
        )

      case 'datetime': {
        let datetimeString = get(formData, field.id, '')
        if (!isEmpty(datetimeString)) {
          datetimeString = DateTime.fromISO(datetimeString)
            .setLocale('en-SG')
            .toFormat('dd LLL yyyy, t')
        } else if (field.id === 'createdOn') {
          datetimeString = getFormattedISODate(new Date())
            .setLocale('en-SG')
            .toFormat('dd LLL yyyy, t')
        }

        return (
          <>
            <div ref={(ref) => (fieldRefs.current[field.id] = ref)} />
            <Input
              key={field.id}
              label={field.label}
              labelSize={labelSize}
              width="300px"
              disabled={!get(field, 'editable', true)}
              labelRight={field.labelRight}
              placeholder={field.placeholder}
              value={datetimeString || ''}
              inputLimit={field.limit}
              as={field.as}
              onChange={(e) => updateFormData(field.id, e.target.value)}
              noMarginTop={noMarginTop}
            />
          </>
        )
      }

      case 'calendar': {
        return (
          <Box style={{ position: 'relative' }}>
            <div ref={(ref) => (fieldRefs.current[field.id] = ref)} />
            {selected === field.id &&
              !(field.id === 'deactivateAfter' && disabledDeactivatedAfter) && (
                <Box
                  ref={calendarRef}
                  style={{
                    position: 'absolute',
                    bottom: 72,
                    right: 8,
                  }}
                >
                  <CalendarWithTime
                    defaultDate={getCalendarDefaultDate(field.id)}
                    onApply={(day) => {
                      setDate(day)
                      setSelected(false)
                      updateFormData(field.id, day)
                    }}
                    onCancel={() => {
                      setSelected(false)
                    }}
                    withActions
                    checkValidDay={(d) => checkValidDate(d, startOfToday())}
                    checkValidMonth={(d) =>
                      isAfter(startOfMonth(startOfToday()), startOfMonth(d))
                    }
                    checkValidYear={(d) =>
                      isAfter(startOfYear(startOfToday()), startOfYear(d))
                    }
                    validateTime
                  />
                </Box>
              )}
            <Flex flexDirection="column" flex={0.48}>
              <Flex>
                <Label
                  fontWeight="700"
                  color="#697481"
                  fontSize={labelSize}
                  mt="16px"
                  mb="4px"
                  flex={1}
                >
                  {field.label}
                </Label>
                {field.required === false ? (
                  <Text
                    fontWeight="500"
                    color="darkShade"
                    fontSize={labelSize}
                    mt="16px"
                    mb="4px"
                    flex={1}
                    textAlign="right"
                    mr={1}
                  >
                    (Optional)
                  </Text>
                ) : null}
              </Flex>

              <InputField
                key={field.id}
                labelSize={labelSize}
                height="46px"
                label={field.label}
                width="300px"
                labelRight={field.labelRight}
                placeholder={field.placeholder}
                value={getCalendarValue(field.id)}
                inputLimit={field.limit}
                disabled={
                  field.id === 'deactivateAfter' && disabledDeactivatedAfter
                }
                endIcon={
                  !disabledDeactivatedAfter && (
                    <FAIcon
                      icon={faTimes}
                      fontSize={14}
                      style={{ cursor: 'pointer' }}
                      onClick={() => updateFormData(field.id, '')}
                    />
                  )
                }
                as={field.as}
                onClick={() => {
                  setSelected(selected ? '' : field.id)
                }}
              />
            </Flex>
          </Box>
        )
      }
      case 'select': {
        return (
          <Flex
            flexDirection="column"
            flex="0 0 50%"
            mt={[1, 0]}
            key={field.id}
            style={{
              display:
                field.visibility === undefined || field.visibility === true
                  ? 'flex'
                  : 'none',
            }}
          >
            <div ref={(ref) => (fieldRefs.current[field.id] = ref)} />
            <Flex>
              <Label
                fontWeight="700"
                color="#697481"
                fontSize={labelSize}
                mt="16px"
                mb="8px"
                mr={1}
                width="auto"
              >
                {field.label}
              </Label>
              {field.required === false && field.hideOptional !== true ? (
                <Text
                  fontWeight="500"
                  color="darkShade"
                  fontSize={labelSize}
                  mt="16px"
                  mb="10px"
                  flex={1}
                  textAlign="right"
                  mr={1}
                >
                  (Optional)
                </Text>
              ) : null}
            </Flex>

            <Select
              mt={2}
              zIndex={10}
              maxMenuHeight={180}
              onChange={(opt) => {
                updateFormData(field.id, opt)
              }}
              options={field.options}
              value={get(formData, field.id, '')}
              st
              {...field.extraProps}
            />
          </Flex>
        )
      }
      case 'editableSelect': {
        return (
          <Flex
            flexDirection="column"
            flex="0 0 50%"
            mt={[1, 0]}
            key={field.id}
            style={{
              display:
                field.visibility === undefined || field.visibility === true
                  ? 'flex'
                  : 'none',
            }}
            data-testid={field.id}
          >
            <div ref={(ref) => (fieldRefs.current[field.id] = ref)} />
            <Flex>
              <Label
                fontWeight="700"
                color="#697481"
                fontSize={labelSize}
                mt="16px"
                mb="8px"
                mr={1}
                width="auto"
              >
                {field.label}
              </Label>
              {field.required === false && field.hideOptional !== true ? (
                <Text
                  fontWeight="500"
                  color="darkShade"
                  fontSize={labelSize}
                  mt="16px"
                  mb="10px"
                  flex={1}
                  textAlign="right"
                  mr={1}
                >
                  (Optional)
                </Text>
              ) : null}
            </Flex>

            <EditableSelect
              mt={2}
              zIndex={10}
              maxMenuHeight={180}
              onChange={(opt) => {
                // Editable select updates parent component, which recalculates defaultFormData
                // which causes division/ department to show previously selected option in case of the value of the option is empty object
                // To cover a case where users can unselect by removing string, we have to wait 1sec
                if (JSON.stringify(opt) === '{}') {
                  setTimeout(() => {
                    updateFormData(field.id, opt)
                  }, [1000])
                } else {
                  updateFormData(field.id, opt)
                }
              }}
              options={field.options}
              value={get(formData, field.id, '')}
              st
              {...field.extraProps}
            />
          </Flex>
        )
      }
      case 'botselect': {
        return (
          <Flex
            mt={-4}
            flexDirection="column"
            flex="0 0 50%"
            key={field.id}
            style={{
              display:
                field.visibility === undefined || field.visibility === true
                  ? 'flex'
                  : 'none',
            }}
          >
            <Flex>
              <Label
                fontWeight="700"
                color="#697481"
                fontSize={labelSize}
                mt="4px"
                mb="8px"
                mr={1}
                width="auto"
              >
                {field.label}
              </Label>
              {field.required === false && field.hideOptional !== true ? (
                <Text
                  fontWeight="500"
                  color="darkShade"
                  fontSize={labelSize}
                  mt="16px"
                  mb="4px"
                  flex={1}
                  textAlign="right"
                  mr={1}
                >
                  (Optional)
                </Text>
              ) : null}
            </Flex>

            <BotSelect
              zIndex={10}
              isMulti={field.isMulti === undefined ? false : field.isMulti}
              maxMenuHeight={180}
              onChange={(opt) => {
                updateFormData(field.id, opt)
              }}
              options={field.options}
              value={get(formData, field.id, '')}
              st
              {...field.extraProps}
            />
          </Flex>
        )
      }
      case 'customPhase1trial':
        return (
          <Box>
            <Flex
              flexDirection="column"
              flex="0 0 50%"
              mt={[1, 0]}
              key={field.id}
            >
              <Flex>
                <Label
                  fontWeight="700"
                  color="#697481"
                  fontSize={labelSize}
                  mt="20px"
                  mb="6px"
                  mr={1}
                  width="auto"
                >
                  Is this {field.label}
                </Label>
              </Flex>

              <EditableSelect
                onChange={(opt) => {
                  updateFormData(field.id, opt)
                }}
                menuPlacement="auto"
                options={field.options}
                value={get(formData, field.id, '')}
                st
              />
            </Flex>
          </Box>
        )
      case 'tags':
        return (
          <Box>
            <Flex
              flexDirection="column"
              flex="0 0 50%"
              mt={[1, 0]}
              key={field.id}
            >
              <Flex>
                <Label
                  fontWeight="700"
                  color="#697481"
                  fontSize={labelSize}
                  mt="18px"
                  mb="8px"
                  mr={1}
                  width="auto"
                >
                  {field.label}
                </Label>
              </Flex>

              <CreatableSelect
                showCopyPaste
                placeholder="Add tags and press enter to create"
                value={() => {
                  const data = get(formData, field.id, '')
                  if (!data) {
                    return []
                  }
                  const value = data.split(',').map((ele) => ({
                    label: ele,
                    value: ele,
                  }))
                  return value
                }}
                options={[]}
                onChange={(opt) => {
                  let selectedTagString = ''
                  opt.forEach((ele, index) => {
                    selectedTagString =
                      index === 0
                        ? ele.value
                        : `${selectedTagString}, ${ele.value}`
                    return 0
                  })
                  updateFormData(field.id, selectedTagString)
                }}
                isDisabled={false}
                isValidNewOption={() => true}
                menuPlacement="auto"
              />
            </Flex>
          </Box>
        )
      case 'custom':
        return (
          <>
            <div ref={(ref) => (fieldRefs.current[field.id] = ref)} />
            <FieldContainer hasError={!!get(formDataError, field.id, '')}>
              {field.component}
            </FieldContainer>
          </>
        )

      default:
        return null
    }
  }

  const renderErrorMessage = (field) => {
    const errorMessage = get(formDataError, field.id, '')
    if (!errorMessage) {
      return null
    }

    let errorText = ''

    if (field.id === 'scheduleMessage') {
      errorText = errorMessage
    } else if (field.id === 'email') {
      errorText = 'Please enter a valid email address'
    } else if (field.type === 'phone') {
      errorText = `Phone number is invalid`
    } else if (field.id === 'deactivateAfter' || field.id === 'activateAfter') {
      errorText = 'Please select a future deactivation date.'
    } else if (field.id === 'firstName' || field.id === 'lastName') {
      errorText = 'Please enter a valid name.'
    } else if (
      field.id === 'groupName' &&
      errorMessage === 'Group with same name already exists'
    ) {
      errorText = 'Group with same name already exists'
    } else {
      errorText = `${startCase(field.id)} is required`
    }

    if (errorText.trim().length) {
      return (
        <Box mt={field.limit ? '-18px' : undefined}>
          <ErrorMessage error={errorText} />
        </Box>
      )
    }

    return null
  }

  const getFormChunkedFields = (form) => {
    let accCurrentIndex = 0
    return form.fields.reduce((acc, field) => {
      if (!acc[accCurrentIndex]) {
        acc[accCurrentIndex] = []
      }

      if (acc[accCurrentIndex].length >= fieldPerRow) {
        accCurrentIndex += 1
        acc[accCurrentIndex] = []
      }

      acc[accCurrentIndex].push(field)

      if (field.fullWidth) {
        accCurrentIndex += 1
      }

      return acc
    }, [])
  }

  return (
    <>
      {map(forms, (form, index) => {
        const fallBackMargin =
          index === forms.length - 1 || form.noMarginBottom ? 0 : 2

        return (
          <Box
            ml={!withinOutlinedBox ? 2 : 0}
            key={form.id}
            mt={index === 0 && !withinOutlinedBox ? 1 : 0}
            mb={`${!withinOutlinedBox ? '48px' : fallBackMargin}`}
          >
            <Flex justifyContent="space-between">
              {startIcon && <Box mr={1}>{startIcon(index)}</Box>}
              <Box width="100%" mt={formSpacing ? '5px' : 0}>
                {form.title && (
                  <Text
                    mb={2}
                    fontWeight={form.fontWeight ? form.fontWeight : '600'}
                    fontSize={form.fontSize ? form.fontSize : 2}
                  >
                    {isQuickEdit ? 'Quick Edit' : form.title}
                  </Text>
                )}
                {form.subTitle && (
                  <Text
                    mt={2}
                    mb={1}
                    color="darkestShade"
                    fontWeight="bold"
                    fontSize={1}
                  >
                    {form.subTitle}
                  </Text>
                )}
                {form.description &&
                  !form.description.includes('create a new group') && (
                    <Text mb={2} fontSize={2} color="darkestShade">
                      {form.description}
                      {form.link && (
                        <a
                          style={{
                            textDecoration: 'underline',
                            color: '#256BF6',
                          }}
                          href={form.link}
                        >
                          here
                        </a>
                      )}
                      {form.callbackText ? (
                        <Text
                          display="inline"
                          color="#256BF6"
                          onClick={form.callback}
                          cursor="pointer"
                        >
                          {form.callbackText}
                        </Text>
                      ) : null}
                    </Text>
                  )}
                {form.description &&
                  form.description.includes('create a new group') && (
                    <Flex>
                      <Text mb={2} fontSize={2} color="darkestShade">
                        {form.description}
                      </Text>
                      {/* <Text
                        cursor="pointer"
                        ml="4px"
                        color="blue"
                        style={{ textDecorationLine: 'underline' }}
                        onClick={() => {
                          history.push('/user_groups')
                        }}
                        mb={1}
                        fontSize={2}
                      >
                        {form.description.slice(59)}
                      </Text> */}
                    </Flex>
                  )}

                {chain(getFormChunkedFields(form))
                  .map((fields, rowIndex) => (
                    <>
                      <Flex key={rowIndex}>
                        {map(fields, (field, fieldIndex) => {
                          if (!isQuickEdit && field.skipField === true) {
                            return null
                          }
                          if (isQuickEdit && !field.quickEditElement) {
                            return null
                          }

                          return (
                            <Box
                              width="100%"
                              data-testid={field.testId}
                              mr={!withinOutlinedBox ? 1 : 0}
                              key={fieldIndex}
                            >
                              {getComponent(
                                field,
                                (form.description && fieldIndex === 0) ||
                                  withinOutlinedBox
                              )}

                              {renderErrorMessage(field)}
                            </Box>
                          )
                        })}
                      </Flex>
                      {!isQuickEdit && fields[0].borderAfter ? (
                        <Box
                          style={{
                            border: '1px solid #d5d7de',
                            borderRadius: '8px',
                            marginTop: '48px',
                            marginBottom: '44px',
                            width: '98%',
                          }}
                        />
                      ) : null}
                    </>
                  ))
                  .value()}
              </Box>
            </Flex>
          </Box>
        )
      })}
    </>
  )
}

export default Form
