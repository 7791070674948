import React, { useEffect, useState, useRef } from 'react'
import { Box, Flex, PrimaryButton, Text, useApi } from '@fivehealth/botero'
import { useQueryClient } from 'react-query'
import usePostHog from 'customHooks/usePostHog'

import { chain } from 'lodash'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import Tabs from 'components/Tabs/Tabs'
import { AlertModalConfirmation } from 'views/AdminSettings/helper'

import { useModal } from '../../../context/ModalContext'
import DocumentExternalLinkForm from './DocumentExternalLinkForm'
import SpinnerIcon from '../../../components/SpinnerIcon/SpinnerIcon'

import DocumentsTable from './DocumentsTable'
import DocumentsExternalLinksTable from './DocumentsExternalLinksTable'
import DocumentsRowSelect from './DocumentsRowSelect'
import EventsConstant from '../../../config/constants/events.constants'

const initialSortBy = {
  id: 'title',
  desc: false,
}

const DocumentsDashboard = () => {
  const [selectedTab, setSelectedTab] = useState('internal_files')
  const onSetTabsRef = (ref) => setSelectedTab(ref.selectedTab)
  const [sorting, setSorting] = useState(initialSortBy)
  const [internalDocs, setInternalDocs] = useState([])
  const [externalDocs, setExternalDocs] = useState([])
  const [rowEditState, setRowEditState] = useState(false)
  const [tableSettings, setTableSettings] = useState(null)
  const { openModal, closeModal } = useModal()
  const queryClient = useQueryClient()
  const [internalDocsCount, setInternalDocsCount] = useState(null)
  const [externalDocsCount, setExternalDocsCount] = useState(null)

  const onCancelRowEditMode = () => {
    setRowEditState(false)
  }

  const {
    queries: {
      useDocumentSyncModule,
      useEinsteinModules,
      useEinsteinAdministrator,
    },
  } = useApi({
    queries: [
      'useDocumentSyncModule',
      'useEinsteinModules',
      'useEinsteinAdministrator',
    ],
  })
  const lastUpdated = useRef(new Date())
  const [showStatusBar, setShowStatusBar] = useState(false)
  const internalDocsMimeType = {
    options: [
      { id: 'application/pdf', label: 'PDF', testId: 'PDF' },
      {
        id: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        label: 'Presentation',
        testId: 'Presentation',
      },
      {
        id: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        label: 'Excel',
        testId: 'Excel',
      },
      { id: 'video/mp4', label: 'Video', testId: 'Video' },
      {
        id: 'audio/mpeg',
        label: 'Audio',
        testId: 'Audio',
      },
    ],
  }

  const externalDocsMimeType = {
    options: [
      { id: 'text/html', label: 'Link', testId: 'Link' },
      { id: 'video/mp4', label: 'Video', testId: 'Video' },
      {
        id: 'audio/mpeg',
        label: 'Audio',
        testId: 'Audio',
      },
    ],
  }

  const [moduleData, setModuleData] = useState({})

  const { mutateAsync: syncDocuments } = useDocumentSyncModule({
    variables: {},
    onSuccess: ({ queryClient: _queryClient }) => {
      openModal(
        <AlertModalConfirmation
          testId="syncDocumentsToast"
          logEventProps={{
            subSource: EventsConstant.WORKSPACE_SETTINGS_LIST,
            eventName: EventsConstant.DEPARTMENT_CREATED_SUCCESS,
            page: EventsConstant.WORKSPACE_SETTINGS_PAGE,
          }}
          title="Sync Initiated"
          description="Documents sync has been initiated. It may take a few minutes to complete."
          closeModal={() => {
            closeModal()
          }}
        />
      )
      if (_queryClient) {
        _queryClient.invalidateQueries('syncDocuments')
      }
    },
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { data: einsteinModuleData } = useEinsteinModules({
    variables: { visible: true },
    onSuccess: ({ data }) => {
      if (data) {
        const [modules] = chain(data)
          .get('pages', [])
          .flatMap((page) => page || [])
          .value()
        if (modules) {
          const { edges } = modules?.einsteinModules

          const chernobylModule = edges?.find(({ node }) =>
            node?.settings?.chernobyl?.key?.toLowerCase()?.includes('documents')
          )
          setModuleData(chernobylModule)
          setTableSettings(
            chernobylModule.node.settings.data_source_entries_table_settings
          )
        }
      }
    },
  })

  useEffect(() => {
    lastUpdated.current = new Date()
  }, [einsteinModuleData])

  useEffect(() => {
    queryClient.invalidateQueries('einsteinModules')
  }, [])

  const logPostHogEvent = usePostHog()

  const orgKey = ((currentAdmin || {}).hospital || {}).organizationKey || ''

  useEffect(() => {
    if (selectedTab === 'internal_files') {
      setInternalDocs(
        chain(internalDocs)
          .orderBy(sorting.id, sorting.desc ? 'desc' : 'asc')
          .value()
      )
    } else {
      setExternalDocs(
        chain(externalDocs)
          .orderBy(sorting.id, sorting.desc ? 'desc' : 'asc')
          .value()
      )
    }
  }, [sorting])

  useEffect(() => {
    logPostHogEvent('dashboard:content_files_and_media_view')
  }, [logPostHogEvent])

  const handleAddExternalLink = () => {
    openModal(<DocumentExternalLinkForm closeModal={closeModal} />, {
      overflow: 'scroll',
    })
  }

  const hasExternalLinksFileSource = !!(
    (moduleData?.node || {}).dataSources || []
  ).find(({ key }) => key.includes('external-links'))

  const tabOptions = [
    {
      id: 'internal_files',
      label: 'Internal Files',
      totalCount:
        internalDocsCount !== null ? (
          internalDocsCount
        ) : (
          <SpinnerIcon spin icon={faSpinner} fontSize="12px" />
        ),
      logEventProps: {
        subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_INTERNAL,
        eventName: EventsConstant.DOCUMENTS_TAB_SELECTED_INTERNAL,
        page: EventsConstant.DOCUMENTS_PAGE,
      },
    },
  ]

  if (hasExternalLinksFileSource) {
    tabOptions.push({
      id: 'external_links',
      testId: 'external_links',
      label: 'External Links',
      totalCount:
        externalDocsCount !== null ? (
          externalDocsCount
        ) : (
          <SpinnerIcon spin icon={faSpinner} fontSize="12px" />
        ),
      logEventProps: {
        subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
        eventName: EventsConstant.DOCUMENTS_TAB_SELECTED_EXTERNAL,
        page: EventsConstant.DOCUMENTS_PAGE,
      },
    })
  }

  return (
    <Box>
      {/* Title */}
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <Text fontSize={5} fontWeight="600">
          Files & Media
        </Text>
        {selectedTab === 'internal_files' ? (
          <PrimaryButton
            borderRadius="8px"
            disabled={showStatusBar}
            data-testid="syncDocumentsButton"
            mr={2}
            onClick={() => {
              syncDocuments({
                input: {
                  moduleUid: moduleData.node.uid,
                },
              })
              setShowStatusBar(true)
              logPostHogEvent('files:sync_files_button_click')
            }}
            logEventProps={{
              subSource: EventsConstant.DOCUMENTS_HEAD_SOURCE,
              eventName: EventsConstant.DOCUMENTS_SYNC_FILES,
              page: EventsConstant.DOCUMENTS_PAGE,
            }}
          >
            Sync Files
          </PrimaryButton>
        ) : (
          <PrimaryButton
            data-testid="syncDocumentsButton"
            borderRadius="8px"
            mr={2}
            onClick={handleAddExternalLink}
            logEventProps={{
              subSource: EventsConstant.DOCUMENTS_HEAD_SOURCE,
              eventName: EventsConstant.DOCUMENTS_ADD_LINK_OPENED,
              page: EventsConstant.DOCUMENTS_PAGE,
            }}
          >
            Add link
          </PrimaryButton>
        )}
      </Flex>
      <Text pl={2} pt={1} pb={4} fontSize="16px">
        View, add and manage files and media for your Hospital app users.
      </Text>

      <Tabs
        m={2}
        tabsRef={onSetTabsRef}
        tabs={tabOptions}
        postHogModule="files"
      />

      <Box
        style={{
          display: selectedTab === 'internal_files' ? 'block' : 'none',
        }}
      >
        {tableSettings && (
          <DocumentsTable
            hospital={orgKey}
            tableSettings={tableSettings}
            mimeType={internalDocsMimeType}
            enableRowEdit={rowEditState}
            enableRowSelect={rowEditState}
            setRowEditState={setRowEditState}
            RowSelectComponent={(props) => (
              <DocumentsRowSelect
                {...props}
                onCancelRowEditMode={onCancelRowEditMode}
                parentPage={EventsConstant.DOCUMENTS_PAGE}
              />
            )}
            selectedTabId={selectedTab}
            onSort={(newSort) => setSorting(newSort)}
            onSetTotalCount={(totalCount) =>
              setInternalDocsCount(totalCount || 0)
            }
            parentPage={EventsConstant.DOCUMENTS_PAGE}
            logPostHogEvent={logPostHogEvent}
            postHogModule="files"
          />
        )}
      </Box>

      {hasExternalLinksFileSource && (
        <Box
          style={{
            display: selectedTab === 'external_links' ? 'block' : 'none',
          }}
        >
          <DocumentsExternalLinksTable
            hospital={orgKey}
            tableSettings={tableSettings}
            mimeType={externalDocsMimeType}
            enableRowEdit={rowEditState}
            enableRowSelect={rowEditState}
            setRowEditState={setRowEditState}
            RowSelectComponent={(props) => (
              <DocumentsRowSelect
                {...props}
                onCancelRowEditMode={onCancelRowEditMode}
                parentPage={EventsConstant.DOCUMENTS_PAGE}
              />
            )}
            selectedTabId={selectedTab}
            onSort={(newSort) => setSorting(newSort)}
            onSetTotalCount={(totalCount) =>
              setExternalDocsCount(totalCount || 0)
            }
            parentPage={EventsConstant.DOCUMENTS_PAGE}
            logPostHogEvent={logPostHogEvent}
            postHogModule="files"
          />
        </Box>
      )}
    </Box>
  )
}

export default DocumentsDashboard
