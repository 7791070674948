import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  H2,
  H6,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
  FAIcon,
  InputField,
  useApi,
  Text,
  CreatableSelect,
  Select,
} from '@fivehealth/botero'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import { useQueryClient } from 'react-query'
import { ErrorMessage } from '@hookform/error-message'
import { useModal } from 'context/ModalContext'

import { useForm, Controller } from 'react-hook-form'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import PermissionsField from 'components/PermissionsField/PermissionsFieldFavourites'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import BotSelect from 'components/BotSelect/BotSelect'
import OutlinedBox from 'components/Box/OutlinedBox'
import ErrorBanner from './ErrorBanner'
import { AlertModalConfirmation } from '../../AdminSettings/helper'
import BotmdCryBaby from '../../../assets/bot-crying-avatar.svg'
import {
  capitalizeFirstLetter,
  constructAclDnfCollection,
} from '../../../Utils'

const Label = (props) => (
  <H6 color="darkestShade" fontSize="12px" lineHeight="20px" {...props} />
)

const InputWithLabel = ({ label, testId, ref, ...props }) => (
  <Flex flexDirection="column" flex="0 0 50%" px={1}>
    <Label>{label}</Label>
    <InputField data-testid={testId} width="auto" maxWidth="auto" {...props} />
  </Flex>
)

const EditDocumentModal = ({ document, closeModal }) => {
  const {
    queries: {
      useEinsteinDocumentEntryUpdate,
      useEinsteinAdministrator,
      useDivisions,
    },
  } = useApi({
    queries: [
      'useEinsteinDocumentEntryUpdate',
      'useEinsteinAdministrator',
      'useDivisions',
    ],
  })

  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const [loaded, setLoaded] = React.useState(false)
  const [showServerError] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const [owner, setSelectedOwner] = useState([])
  const [toSync, setToSync] = useState(false)
  const [aclDnf, setAclDnf] = useState([])
  const [selectedDepartmentForm, setSelectedDepartmentForm] = useState([])
  const [selectedDesignationForm, setSelectedDesignationForm] = useState([])
  const { data } = {}
  const { openModal } = useModal()

  const { mutateAsync: updateDocument } = useEinsteinDocumentEntryUpdate({
    variables: {},
    onSuccess: ({ queryClient: successQueryClient, data: resdata }) => {
      if (successQueryClient) {
        let modalProps
        if (resdata?.errors && resdata?.errors.length) {
          modalProps = {
            title: 'Could not update documents',
            description: resdata?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
            botmdImage: BotmdCryBaby,
          }
        } else {
          modalProps = {
            description: 'Document Successfully Updated',
          }
        }
        openModal(
          <AlertModalConfirmation
            testId="documentEditModal"
            closeModal={() => {
              closeModal()
            }}
            {...modalProps}
          />
        )
      }
    },
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const statusOpts = [
    { label: 'Synced', value: 'synced' },
    { label: 'Ignored', value: 'ignored' },
  ]

  const { data: dataDivision } = useDivisions().paginated({
    variables: {},
  })

  const allDivisionOptions = useMemo(() => {
    const desingations = dataDivision?.pages?.[0] || []
    const list = desingations
    const uniqueUids = new Set(list?.map((obj) => obj.uid))

    const uniqueDesignations = list?.filter(
      (obj, index) => Array.from(uniqueUids).indexOf(obj.uid) === index
    )
    return uniqueDesignations?.map((designation) => ({
      label: designation.name,
      value: designation.uid,
      type: 'division',
    }))
  }, [dataDivision])
  const [invalidatingVariable] = useState(Date.now())

  const allDepartmentsOptions = useCompleteDepartments({
    uidInValue: true,
    extraOptionsData: { type: 'department' },
    invalidatingVariable,
  })

  const defaultValues = {
    filename: document?.misc?.filename,
    title: document?.title,
    folder: document?.folder === '-' ? '' : document?.folder,
    url: document?.misc?.webUrl,
    author: document?.author,
    tags: document?.tags,
    ignore: document?.ignore,
    status: document?.status
      ? {
          label: document.status ? capitalizeFirstLetter(document.status) : '',
          value: document.status ? document.status : '',
        }
      : '',
    misc: document.misc,
  }

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  })

  const getACL = () => {
    const filteredDepartment = selectedDepartmentForm.filter(
      (ele) => ele.uid !== 'allDepartment'
    )

    const filteredDesignation = selectedDesignationForm.filter(
      (ele) => ele.uid !== 'allDesignation'
    )
    const aclDnfCollection = constructAclDnfCollection(
      filteredDepartment,
      filteredDesignation
    )
    const orgKey = currentAdmin.hospital.organizationKey

    if (aclDnfCollection.length) {
      const aclDnfString = aclDnfCollection.map(
        (aclDnfRow) =>
          `hospital:hospital=${orgKey}^${
            Array.isArray(aclDnfRow) ? aclDnfRow.join('^') : aclDnfRow
          }`
      )

      return aclDnfString
    }
    return [`hospital:hospital=${orgKey}`]
  }

  const handleValidation = (message) => {
    if (message) {
      setError('permissions', {
        type: 'manual',
        message,
      })
    } else {
      clearErrors('permissions')
    }
  }
  const onSubmit = async (formData) => {
    const { title, misc } = formData
    const editedDocument = {
      uid: misc.uid,
      title,
      aclDnf: getACL(),
      syncJarvis: true,
      departmentUid: owner?.id,
      variablesSet: [
        {
          name: 'owner',
          value: owner?.value || '',
        },
        {
          name: 'status',
          value: toSync ? '' : 'ignore',
        },
        {
          name: 'tags',
          value:
            selectedTags.length > 0
              ? selectedTags.map(({ value }) => value).toString()
              : '',
        },
      ],
    }
    if (owner.type) {
      editedDocument.ownerDepartments =
        owner.type === 'department' ? [{ uid: owner?.id }] : []
      editedDocument.ownerDivisions =
        owner.type === 'division' ? [{ uid: owner?.id }] : []
    }

    return updateDocument({ input: editedDocument }).then(() => {
      queryClient.invalidateQueries('einsteinDocuments')
      return null
    })
  }

  useEffect(() => {
    defaultValues.tags = selectedTags
    if (document.tags) {
      const tags = document.tags.split(',')
      setSelectedTags(tags.map((tag) => ({ value: tag, label: tag })))
    }

    setToSync(!document.ignore)

    const { ownerDepartments, ownerDivisions } = document?.misc
    if (ownerDepartments?.edges?.length) {
      const owneDep = ownerDepartments?.edges?.[0]?.node
      setSelectedOwner({
        id: owneDep.uid,
        label: owneDep.name,
        value: owneDep.name,
      })
    } else if (ownerDivisions?.edges?.length) {
      const ownerDiv = ownerDivisions?.edges?.[0]?.node
      setSelectedOwner({
        id: ownerDiv.uid,
        label: ownerDiv.name,
        value: ownerDiv.name,
      })
    }

    if (document) {
      setAclDnf(
        document.aclDnf || [
          `hospital:hospital=${currentAdmin.hospital.organizationKey}`,
        ]
      )
    }
  }, [document])

  useEffect(() => {
    if (!loaded && data && statusOpts.length > 0) {
      setLoaded(true)
    }
  }, [data, statusOpts])

  const maxHeight = (window.screen.height * 90) / 100
  return (
    <OutlinedBox style={{ maxHeight: `${maxHeight}px`, overflowY: 'auto' }}>
      <Box m={4} width="580px" data-testid="editDocumentModal">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex mb={2} justifyContent="space-between" alignItems="center">
            <H2>{t('Edit Document Settings')}</H2>
            <Box cursor="pointer" onClick={closeModal}>
              <FAIcon
                icon={faTimes}
                hover={{ opacity: 0.6 }}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Flex>
          <Box>
            {showServerError && (
              <ErrorBanner
                mt={2}
                text={t(
                  'An error has occurred. Please check your input and try submitting again.'
                )}
              />
            )}

            <>
              <Box mx={-1} mt={32}>
                <Controller
                  name="filename"
                  control={control}
                  disabled
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <InputWithLabel
                      label={t('File name')}
                      {...field}
                      disabled
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="filename"
                  message={t('Filename required.')}
                  render={({ message }) => (
                    <Text color="danger" fontSize={12} mt={1} ml={1}>
                      {t(message)}
                    </Text>
                  )}
                />
              </Box>

              <Box mx={-1} mt={16}>
                <Controller
                  name="url"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { ref, ...field } }) => (
                    <InputWithLabel
                      label={t('Source URL')}
                      {...field}
                      disabled
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="url"
                  message={t('url required.')}
                  render={({ message }) => (
                    <Text color="danger" fontSize={12} mt={1} ml={1}>
                      {t(message)}
                    </Text>
                  )}
                />
              </Box>

              <Box mx={-1} mt={16}>
                <Controller
                  name="folder"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputWithLabel label={t('Folder')} {...field} disabled />
                  )}
                />
              </Box>

              <Box mx={-1} mt={16}>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <InputWithLabel
                      label={t('Title')}
                      {...field}
                      disabled={false}
                      data-testid="linkTitle"
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="title"
                  message={t('Title required.')}
                  render={({ message }) => (
                    <Text color="danger" fontSize={12} mt={1} ml={1}>
                      {t(message)}
                    </Text>
                  )}
                />
              </Box>

              <Flex mt={16} mx={-1} flexDirection={['column', 'row']}>
                <Box flex={[1, 1]} mt={[1, 0]} ml={1} mr={1}>
                  <Label>{t('Owner')}</Label>
                  <Controller
                    name="author"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        zIndex={10}
                        onChange={(value) => {
                          setSelectedOwner({
                            id: value.value,
                            label: value.label,
                            value: value.label,
                            type: value.type,
                          })
                        }}
                        options={[
                          {
                            label: 'Division',
                            options: allDivisionOptions,
                          },
                          {
                            label: 'Departments',
                            options: allDepartmentsOptions,
                          },
                        ]}
                        value={owner}
                        st
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="author"
                    message={t('Author required.')}
                    render={({ message }) => (
                      <Text color="danger" fontSize={12} mt={1} ml={1}>
                        {t(message)}
                      </Text>
                    )}
                  />
                </Box>
              </Flex>

              <Box mt={16}>
                <Label>{t('Tags')}</Label>
                <Controller
                  name="tags"
                  control={control}
                  rules={{ required: false }}
                  render={() => (
                    <CreatableSelect
                      showCopyPaste
                      placeholder="Add tags and press enter to create"
                      value={selectedTags}
                      options={[]}
                      onChange={setSelectedTags}
                      isDisabled={false}
                      isValidNewOption={() => true}
                      isOptionDisabled={({ label }) =>
                        isEqual(label, 'Select Tags')
                      }
                      menuPlacement="auto"
                    />
                  )}
                />
              </Box>
            </>

            <Flex mt={16} mb={16} mx={-1} flexDirection={['column', 'row']}>
              <Box flex={[1, 1]} mt={[1, 0]} ml={1} mr={1}>
                <Label>{t('Status')}</Label>
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: false }}
                  render={() => (
                    <BotSelect
                      options={statusOpts}
                      onChange={(selected) => {
                        setToSync(
                          !selected?.value?.toLowerCase().includes('ignore')
                        )
                      }}
                      value={toSync ? statusOpts[0] : statusOpts[1]}
                      isMulti={false}
                      isClearable={false}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="status"
                  message={t('Status required.')}
                  render={({ message }) => (
                    <Text color="danger" fontSize={12} mt={1} ml={1}>
                      {t(message)}
                    </Text>
                  )}
                />
              </Box>
            </Flex>

            <Controller
              name="permissions"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <PermissionsField
                  onChange={(newAclDnf) => {
                    // field.onChange(newAclDnf) // Notify React Hook Form of the change
                    setAclDnf(newAclDnf)
                  }}
                  {...field}
                  aclDnf={aclDnf}
                  onValidation={handleValidation}
                  setSelectedDepartmentForm={setSelectedDepartmentForm}
                  setSelectedDesignationForm={setSelectedDesignationForm}
                  orgKey={currentAdmin.hospital.organizationKey}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="permissions"
              message={t('Department Selection is required.')}
              render={({ message }) => (
                <Text color="danger" fontSize={12} mt={1} ml={1}>
                  {t(message)}
                </Text>
              )}
            />

            <Flex mt={7} justifyContent="flex-end">
              <SecondaryOutlinedButton
                type="button"
                borderRadius="8px"
                onClick={closeModal}
              >
                {t('Cancel')}
              </SecondaryOutlinedButton>
              <PrimaryButton
                borderRadius="8px"
                ml={3}
                type="submit"
                disabled={!isValid}
                data-testid="saveDocumentButton"
              >
                {t('Save')}
              </PrimaryButton>
            </Flex>
          </Box>
        </form>
      </Box>
    </OutlinedBox>
  )
}

EditDocumentModal.propTypes = {
  modalTitle: PropTypes.string,
}

EditDocumentModal.defaultProps = {
  modalTitle: '',
}
export default EditDocumentModal
