import {
  Box,
  DataTable,
  FAIcon,
  Flex,
  InputField,
  SecondaryOutlinedButton,
  useApi,
  useDebounce,
} from '@fivehealth/botero'
import { useHistory } from 'react-router-dom'
import { faCog, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import { chain, first, includes, isEmpty, startCase } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Filter from 'views/Filter/Filter'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import useCompleteDesignations from 'customHooks/useCompleteDesignations'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import getCell, { onToggleFilterCallback } from '../../../../Utils'
import UserManagementTableRowEdit from './UserManagementTableRowEdit'
import EventsConstant from '../../../../config/constants/events.constants'
import ProvisionBadge from '../../../../components/ProvisionBadge'

const excludeColumns = [
  'uid',
  'fullName',
  'identifiableInformations',
  'firstName',
  'lastName',
  'phone',
  'deactivatedOn',
]
const sortColumn = ['name', 'createdOn', 'firstName', 'lastName']

const labelDisplayMap = {
  activateAfter: 'Activation on',
  deactivateAfter: 'Deactivation on',
}

const UserManagementTable = ({
  onUpdateFilterChange, // to notify dashboard view of filter changes for export button
  RowSelectComponent,
  filterParams,
  setSelectAllRows,
  selectAllRows,
  resetTable = false,
  enableRowEdit = true,
  selectAllRowsFromTable,
  disableCheckboxes = false,
  rowEditState,
  setRowEditState,
  selectedRows,
  setSelectedRows,
  defaultProfileActivationStatusIn,
  existingUids = [],
  totalCount = 0,
  logPostHogEvent = () => {},
  postHogModule = '',
}) => {
  const history = useHistory()
  const dropdownRef = useRef()
  const tableRef = useRef()
  const [searchQuery, setSearchQuery] = useState('')
  const [filter, setFilter] = useState({
    search: '',
    profileActivationStatusIn: defaultProfileActivationStatusIn,
  })

  const debouncedSearchQuery = useDebounce(searchQuery, 200)
  useEffect(() => {
    logPostHogEvent(`${postHogModule}:search_submit`, {
      search_term: debouncedSearchQuery,
    })
  }, [debouncedSearchQuery])

  const DEFAULT_PARAMS = {
    orderDesc: true,
    first: 25,
    orderField: 'createdOn',
    profileActivationStatusIn: defaultProfileActivationStatusIn,
    profileStatus: isEmpty(filterParams)
      ? {
          ACTIVATED_WITHOUT_SESSION: false,
          ACTIVATED_WITH_SESSION: false,
          DEACTIVATED: false,
        }
      : {},
  }

  const [prevSortParams, setPrevSortParams] = useState({
    id: DEFAULT_PARAMS.orderField,
    desc: true,
  })

  const [sortParams, setSortParams] = useState({
    id: DEFAULT_PARAMS.orderField,
    desc: true,
  })

  useEffect(() => {
    onUpdateFilterChange({ ...filter, search: debouncedSearchQuery })
    if (!isEmpty(sortParams)) {
      setPrevSortParams(sortParams)
    }
    if (debouncedSearchQuery.length > 0) {
      setSortParams({}) // for search the priority is assigned by the search ranking, adding sort params affects the results
    } else {
      setSortParams(prevSortParams)
    }
    const newFilter = { ...filter, search: debouncedSearchQuery }
    if (!newFilter.department || !newFilter.department.length) {
      delete newFilter.department
    }

    if (!newFilter.designation || !newFilter.designation.length) {
      delete newFilter.designation
    }
    const filtersToSet = {
      ...filter,
      ...newFilter,
    }
    if (defaultProfileActivationStatusIn?.length) {
      filtersToSet.profileActivationStatusIn = defaultProfileActivationStatusIn
    }
    setFilter(filtersToSet)
  }, [debouncedSearchQuery])

  useEffect(() => {
    if (!isEmpty(filterParams)) {
      // Below line breaks user status filters in user group
      // delete filter.profileActivationStatusIn
      const newFilter = { ...filter, search: debouncedSearchQuery }
      if (!newFilter.department || !newFilter.department.length) {
        delete newFilter.department
      }

      if (!newFilter.designation || !newFilter.designation.length) {
        delete newFilter.designation
      }
      const filtersToSet = {
        ...filter,
        ...newFilter,
      }
      if (defaultProfileActivationStatusIn?.length) {
        filtersToSet.profileActivationStatusIn =
          defaultProfileActivationStatusIn
      }
      setFilter(filtersToSet)
    }
  }, [filterParams])

  const tableParams = useRef(DEFAULT_PARAMS)

  const [showFilters, setShowFilters] = useState(false)
  const [activeFilters, setActiveFilters] = useState(
    isEmpty(filterParams)
      ? {
          profileStatus: {
            ACTIVATED_WITHOUT_SESSION: false,
            ACTIVATED_WITH_SESSION: false,
            DEACTIVATED: false,
          },
        }
      : {}
  )
  let visibleRows = []

  const setVisibleRows = (rows) => {
    visibleRows = rows
  }

  const onToggleFilters = (open) => {
    const department = {}
    filter?.department?.forEach((ele) => {
      department[ele] = true
    })
    const designation = {}
    filter?.designation?.forEach((ele) => {
      designation[ele] = true
    })
    const profileStatus = {}
    filter?.profileActivationStatusIn?.forEach((ele) => {
      profileStatus[ele] = true
    })
    setShowFilters(open)
    if (!open) {
      setActiveFilters({
        profileStatus,
        designation,
        department,
      })
    }
  }

  const onResetFilters = () => {
    tableParams.current = {
      designation: [],
      department: [],
      profileActivationStatusIn: [],
      ...tableParams.current,
    }
    setShowFilters(false)
    setActiveFilters({})

    const filterObj = {}
    onUpdateFilterChange(filterObj)
    setFilter({
      search: filter.search,
      profileActivationStatusIn: defaultProfileActivationStatusIn,
    })
  }

  const onFilterCancel = () => {
    setActiveFilters({
      ...tableParams.current,
    })
  }

  const departmentFilters = {
    id: 'department',
    title: 'Department',
    data: [],
    multiSelect: true,
  }

  const designationFilters = {
    id: 'designation',
    title: 'Designation',
    data: [],
    multiSelect: true,
  }

  const profileActivationFilters = {
    id: 'profileStatus',
    title: 'Status',
    data: [
      {
        id: 'ACTIVATED_WITH_SESSION',
        testId: 'ACTIVATED_WITH_SESSION',
        label: 'Signed-in',
      },
      {
        id: 'ACTIVATED_WITHOUT_SESSION',
        testId: 'ACTIVATED_WITHOUT_SESSION',
        label: 'Awaiting Sign-in',
      },
      {
        id: 'DEACTIVATED',
        testId: 'DEACTIVATED',
        label: 'Deactivated',
      },
      {
        id: 'AWAITING_ACTIVATION',
        testId: 'AWAITING_ACTIVATION',
        label: 'Pending Activation',
      },
      {
        id: 'AWAITING_ACTIVATION_ERROR',
        testId: 'AWAITING_ACTIVATION_ERROR',
        label: 'Error',
      },
    ],
    multiSelect: true,
  }

  const {
    queries: {
      useDirectoryProfile,
      useEinsteinAdministrator,
      useEinsteinSettingsWithId,
    },
  } = useApi({
    queries: [
      'useDirectoryProfile',
      'useEinsteinAdministrator',
      'useEinsteinSettingsWithId',
    ],
  })

  departmentFilters.data = useCompleteDepartments()
  designationFilters.data = useCompleteDesignations()

  const {
    data: directoryProfilesData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useDirectoryProfile({
    variables: {
      sortBy: sortParams.id,
      sortDesc: sortParams.desc,
      ...filter,
      ...filterParams,
    },
  })

  const { data: directoryProfilesDCount } = useDirectoryProfile({
    variables: {
      profileActivationStatusIn: ['ACTIVATED_WITH_SESSION'],
    },
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { data: hospitalSettingsData } = useEinsteinSettingsWithId({
    variables: {
      uid: currentAdmin?.hospital?.uid,
    },
  })

  function getKeysByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value)
  }

  const onApplyFilters = (filters) => {
    tableParams.current = {
      ...tableParams.current,
      ...activeFilters,
    }
    let selectedDesignationkey = []
    let selectedDepartmentKey = []
    let selectedProfileStatusKey = []

    if (filters.designation) {
      selectedDesignationkey = getKeysByValue(filters.designation, true)
    }
    if (filters.department) {
      selectedDepartmentKey = getKeysByValue(filters.department, true)
    }
    if (filters.profileStatus) {
      selectedProfileStatusKey = getKeysByValue(filters.profileStatus, true)
    }

    tableParams.current = {
      profileActivationStatusIn: selectedProfileStatusKey,
      ...tableParams.current,
      ...activeFilters,
    }

    const filterObj = {
      profileActivationStatusIn: selectedProfileStatusKey,
    }

    if (selectedDesignationkey.length) {
      tableParams.current.designation = selectedDesignationkey
      filterObj.designation = selectedDesignationkey
    }

    if (selectedDepartmentKey.length) {
      tableParams.current.department = selectedDepartmentKey
      filterObj.department = selectedDepartmentKey
    }

    setShowFilters(false)
    if (selectAllRows) {
      setRowEditState(false)
      setSelectAllRows(false)
    }

    if (isEmpty(selectedProfileStatusKey)) {
      delete tableParams.current.profileActivationStatusIn
      delete filterObj.profileActivationStatusIn
    }

    onUpdateFilterChange({ ...filterObj, search: debouncedSearchQuery })
    const filtersToSet = {
      ...filterObj,
      search: debouncedSearchQuery,
    }
    if (defaultProfileActivationStatusIn?.length) {
      filtersToSet.profileActivationStatusIn = defaultProfileActivationStatusIn
    }
    setFilter(filtersToSet)
  }

  const onToggleFilter = ({ nextFilterState }) => {
    onToggleFilterCallback(
      nextFilterState,
      filter,
      activeFilters,
      setActiveFilters
    )
  }

  const directoryProfiles = chain(directoryProfilesData)
    .get('pages', [])
    .flatMap((page) => page || [])
    .filter((data) => !existingUids.includes(data.uid)) // Filtering out existing UIDs
    .map((data) => ({
      uid: data.uid,
      name: `${data.firstName} ${data.lastName} :${
        data.identifiableInformations.length > 0
          ? data.identifiableInformations[0].encryptedValue
          : ''
      }`,
      designation: data.designation,
      department: data.department,
      status: data.status,
      createdOn: data.createdOn,
      activateAfter: data.activateAfter,
      deactivateAfter: data.deactivatedOn
        ? data.deactivatedOn
        : data.deactivateAfter,
    }))
    .value()

  const getColumnWidth = (column) => {
    switch (column) {
      case 'status':
        return '80px'
      case 'name':
        return '150px'
      case 'createdOn':
        return '100px'
      default:
        return '110px'
    }
  }

  const createColumns = useCallback((profiles) => {
    if (!isEmpty(profiles)) {
      const firstProfile = first(profiles)
      const keys = Object.keys(firstProfile).filter(
        (key) => !includes(excludeColumns, key)
      )
      return keys.map((key) => {
        const result = {
          id: key,
          accessor: key,
          Header: startCase(labelDisplayMap[key] ? labelDisplayMap[key] : key),
          disableSortBy: !includes(sortColumn, key),
          Cell: ({ row, value }) => {
            const renderedRow = { uid: row.original.uid, row }
            visibleRows.push(renderedRow)
            return getCell(key, value, row)
          },
          width: getColumnWidth(key),
        }
        setVisibleRows(visibleRows)
        return result
      })
    }
    return []
  }, [])

  const handleTableRowClick = (row) => {
    if (selectAllRows) {
      setSelectAllRows(false)
      setSelectedRows([])
      setRowEditState(false)
    }
    if (!rowEditState && dropdownRef.current && !dropdownRef.current.open) {
      history.push(`/user_management/${row.original.uid}`)
    } else if (
      rowEditState ||
      (dropdownRef.current && !dropdownRef.current.open)
    ) {
      row.toggleRowSelected()
      const index = selectedRows
        ? selectedRows.findIndex(
            (selectedRow) => selectedRow.original.uid === row.original.uid
          )
        : -1
      if (index > -1) {
        selectedRows.splice(index, 1)
        setSelectedRows([...selectedRows])
        return
      }
      setSelectedRows([...selectedRows, row])
    }
  }

  const actions = [
    {
      id: 'selectAll',
      label: 'Select all users',
      onClick: () => {
        setRowEditState(true)
        setTimeout(() => selectAllRowsFromTable(true), 100)
      },
      logEventProps: {
        subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
        eventName: EventsConstant.USER_MANAGEMENT_ALL_SELECTION,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'customSelections',
      divider: 'true',
      enable: !selectAllRows,
      label: 'Custom selection',
      onClick: () => {
        if (!selectAllRows) {
          setSelectAllRows(false)
          setRowEditState(true)
        }
      },
      logEventProps: {
        subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
        eventName: EventsConstant.USER_MANAGEMENT_CUSTOM_SELECTION,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
  ]

  const getNoResultsProps = () => {
    if (!searchQuery) {
      return {
        avatarProps: { height: '200px', width: '200px' },
        title:
          !directoryProfiles.length && totalCount
            ? 'There is no user from your department in this user group'
            : 'No user data',
        description: '',
      }
    }

    return { avatarProps: { height: '200px', width: '200px' } }
  }

  const getDataTable = () => {
    const data = (
      <Box
        style={{
          display: isLoading ? 'none' : '',
        }}
        data-testid="userManagementTable"
      >
        <DataTable
          columns={createColumns(directoryProfiles)}
          mb={4}
          show="false"
          data={directoryProfiles}
          hasNextPage={selectAllRows ? false : hasNextPage}
          onFetchData={({ sortBy }) => {
            if (sortBy[0].id === 'name') {
              setSortParams({ ...sortBy[0], id: 'firstName' })
            } else {
              setSortParams(sortBy[0])
            }
          }}
          onFetchNextPage={() => {
            logPostHogEvent(`${postHogModule}:load_more_button_click`)
            fetchNextPage()
          }}
          hideHeaderSelectionCheckBox
          initialSortBy={sortParams}
          isFetchingNextPage={isFetchingNextPage}
          noResultsProps={getNoResultsProps()}
          disableCheckboxes={disableCheckboxes}
          onRowClick={handleTableRowClick}
          enableRowSelect={rowEditState}
          showRowSelectedBar
          renderRowSelectInfo={(props) => RowSelectComponent(props)}
          enableRowEdit={enableRowEdit}
          autoResetSelectedRows={resetTable}
          renderRowEdit={({ original }) =>
            enableRowEdit && (
              <UserManagementTableRowEdit
                data={original}
                showInfoView={(props) =>
                  history.push(`/user_management/${original.uid}`, {
                    // Add this to open user edit form after navigation
                    from: props.openEdit ? 'user_management_edit' : null,
                  })
                }
                dropdownRef={dropdownRef}
              />
            )
          }
          logEventProps={{
            subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
            eventName: EventsConstant.USER_PROFILE_OPNED,
            page: EventsConstant.USER_MANAGEMENT_PAGE,
          }}
        />
      </Box>
    )
    tableRef.current = data
    return data
  }

  return (
    <Box>
      {/* Search bar */}
      <Flex m={2} justifyContent="space-between" alignItems="center" pr="16px">
        <Flex alignItems="center">
          <Box flex={[0.95, null]}>
            <InputField
              disabled={selectAllRows}
              data-testid="userManagementSearchBox"
              justifyContent="space-between"
              alignItems="center"
              placeholder="Search by user name or mobile"
              startIcon={
                <FAIcon icon={faSearch} fontSize={16} color="fullShade" />
              }
              width={['100%', 500]}
              fontWeight="400"
              height="43.5px"
              lineHeigt="24px"
              fontSize={[14]}
              endIcon={
                !isEmpty(searchQuery) && (
                  <FAIcon
                    cursor="pointer"
                    onClick={() => {
                      if (!selectAllRows) {
                        setSearchQuery('')
                      }
                    }}
                    icon={faTimes}
                    fontSize={16}
                    color="fullShade"
                  />
                )
              }
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
          {!filterParams && (
            <ProvisionBadge
              parentPage={EventsConstant.USER_MANAGEMENT_PAGE}
              currentCount={directoryProfilesDCount?.totalCount}
              limit={
                hospitalSettingsData?.hospitalHospital?.settings?.einstein
                  ?.directoryprofile_provision_limit
              }
            />
          )}
        </Flex>

        <Flex alignItems="center">
          {JSON.stringify(defaultProfileActivationStatusIn) !==
            JSON.stringify(['DEACTIVATED']) && (
            <DropdownMenu
              moveLeft="-3px"
              fontWeight="400"
              label={
                <SecondaryOutlinedButton
                  data-testid="userGroupMutateButton"
                  mr={2}
                  onClick={() => {
                    // setRowEditState(!rowEditState)
                  }}
                  display={directoryProfiles.length === 0 ? 'none' : 'flex'}
                  endIcon={
                    <FAIcon icon={faCog} fontSize={14} color="darkestShade" />
                  }
                  borderRadius="8px"
                  color="darkestShade"
                  logEventProps={{
                    subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
                    eventName: EventsConstant.USERS_ACTIONS_OPENED,
                    page: EventsConstant.USER_MANAGEMENT_PAGE,
                  }}
                >
                  Actions
                </SecondaryOutlinedButton>
              }
              actions={actions}
              width="200px"
            />
          )}
          <Filter
            postHogModule="user_list"
            testId="filterUserButton"
            activeFilters={activeFilters}
            minWidth={550}
            filterOptions={[
              designationFilters,
              departmentFilters,
              isEmpty(defaultProfileActivationStatusIn) &&
                profileActivationFilters,
            ]}
            onFilterChange={onToggleFilter}
            open={showFilters}
            onOpen={onToggleFilters}
            onResetFilters={onResetFilters}
            onSave={onApplyFilters}
            onCancel={onFilterCancel}
            logEventPropsApply={{
              subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
              eventName: EventsConstant.USERS_FILTERS_APPLIED,
              page: EventsConstant.USER_MANAGEMENT_PAGE,
            }}
            logEventPropsCancel={{
              subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
              eventName: EventsConstant.USERS_FILTERS_CENCELED,
              page: EventsConstant.USER_MANAGEMENT_PAGE,
            }}
            logEventPropsOpened={{
              subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
              eventName: EventsConstant.USERS_FILTERS_OPENED,
              page: EventsConstant.USER_MANAGEMENT_PAGE,
            }}
            logEventPropsReset={{
              subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
              eventName: EventsConstant.USERS_FILTERS_RESET,
              page: EventsConstant.USER_MANAGEMENT_PAGE,
            }}
          />
        </Flex>
      </Flex>

      {/* Table */}
      <Box ml={20} mr={20}>
        {getDataTable()}
        {isLoading ? <ProgressAnimation /> : <Box />}
      </Box>
    </Box>
  )
}

export default UserManagementTable
