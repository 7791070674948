import React, { useState } from 'react'

import {
  Box,
  H2,
  H6,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
  FAIcon,
  useApi,
  Text,
} from '@fivehealth/botero'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import PermissionsField from 'components/PermissionsField/PermissionsFieldFavourites'
import { constructAclDnfCollection } from 'Utils'
import { map } from 'lodash'
import BotSelect from 'components/BotSelect/BotSelect'
import { ErrorMessage } from '@hookform/error-message'
import ErrorBanner from './ErrorBanner'
import BotmdCryBaby from '../../../assets/bot-crying-avatar.svg'
import { AlertModalConfirmation } from '../../AdminSettings/helper'

const Label = (props) => (
  <H6 color="darkestShade" fontSize="12px" lineHeight="20px" {...props} />
)

const DocumentBulkEditModal = ({
  selectedFlatRows,
  toggleAllRowsSelected,
  rowSelectionType,
  onCancelRowEditMode,
  closeModal,
  openModal,
  filters,
}) => {
  const {
    queries: {
      useEinsteinDocumentEntryUpdate,
      useEinsteinAdministrator,
      useBulkUpdateDocuments,
    },
  } = useApi({
    queries: [
      'useEinsteinDocumentEntryUpdate',
      'useEinsteinAdministrator',
      'useBulkUpdateDocuments',
    ],
  })

  const { t } = useTranslation()
  const [showServerError] = useState()
  const [toSync, setToSync] = useState(true)
  const [aclDnf, setAclDnf] = useState([])
  const [selectedDepartmentForm, setSelectedDepartmentForm] = useState([])
  const [selectedDesignationForm, setSelectedDesignationForm] = useState([])
  const queryClient = useQueryClient()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setError,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    criteriaMode: 'all',
  })
  const statusOpts = [
    { label: 'Synced', value: 'synced' },
    { label: 'Ignored', value: 'ignored' },
  ]

  const { mutateAsync: updateDocumentInBulk } = useBulkUpdateDocuments({
    variables: {},
    onSuccess: ({ queryClient: successQueryClient, data }) => {
      if (successQueryClient) {
        if (data?.errors && data?.errors.length) {
          const modalProps = {
            title: 'Could not update documents',
            description: data?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
            botmdImage: BotmdCryBaby,
          }
          openModal(
            <AlertModalConfirmation
              closeModal={() => {
                onCancelRowEditMode()
                toggleAllRowsSelected(false)
                closeModal()
                queryClient.invalidateQueries('einsteinDocuments')
                queryClient.invalidateQueries('einsteinDocumentsExternal')
              }}
              {...modalProps}
            />
          )
          return
        }
        queryClient.invalidateQueries('einsteinDocuments')
        queryClient.invalidateQueries('einsteinDocumentsExternal')

        onCancelRowEditMode()
        toggleAllRowsSelected(false)
        closeModal()
      }
    },
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const getACL = () => {
    const filteredDepartment = selectedDepartmentForm.filter(
      (ele) => ele.uid !== 'allDepartment'
    )
    const filteredDesignation = selectedDesignationForm.filter(
      (ele) => ele.uid !== 'allDesignation'
    )
    const aclDnfCollection = constructAclDnfCollection(
      filteredDepartment,
      filteredDesignation
    )
    const orgKey = currentAdmin.hospital.organizationKey

    if (aclDnfCollection.length) {
      const aclDnfString = aclDnfCollection.map(
        (aclDnfRow) =>
          `hospital:hospital=${orgKey}^${
            Array.isArray(aclDnfRow) ? aclDnfRow.join('^') : aclDnfRow
          }`
      )

      return aclDnfString
    }
    return [`hospital:hospital=${orgKey}`]
  }

  const { mutateAsync: updateDocument } = useEinsteinDocumentEntryUpdate({
    variables: {},
    onSuccess: ({ queryClient: successQueryClient, data }) => {
      if (successQueryClient) {
        if (data?.errors && data?.errors.length) {
          const modalProps = {
            title: 'Could not update documents',
            description: data?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
            botmdImage: BotmdCryBaby,
          }
          openModal(
            <AlertModalConfirmation
              closeModal={() => {
                onCancelRowEditMode()
                toggleAllRowsSelected(false)
                closeModal()
                queryClient.invalidateQueries('einsteinDocuments')
                queryClient.invalidateQueries('einsteinDocumentsExternal')
              }}
              {...modalProps}
            />
          )
          return
        }
        successQueryClient.invalidateQueries('einsteinDocuments')
        queryClient.invalidateQueries('einsteinDocumentsExternal')
        onCancelRowEditMode()
        toggleAllRowsSelected(false)
        closeModal()
      }
    },
  })

  const handleValidation = (message) => {
    if (message) {
      setError('permissions', {
        type: 'manual',
        message,
      })
    } else {
      clearErrors('permissions')
    }
  }

  const onSubmit = async () => {
    if (rowSelectionType === 'selectAll') {
      updateDocumentInBulk({
        bulkUpdateInput: {
          fields: {
            aclDnf: getACL(),
            deactivate: false,
            syncJarvis: true,
            syncStatus: toSync ? '' : 'ignore',
          },
          filters,
        },
      })
    } else {
      Promise.all(
        map(selectedFlatRows, (row) =>
          updateDocument({
            input: {
              uid: row.original.uid,
              aclDnf: getACL(),
              variablesSet: [
                ...Object.keys(row.original.misc.variables).map(
                  (variableKey) => ({
                    name: variableKey,
                    value: row.original.misc.variables[variableKey],
                  })
                ),
                {
                  name: 'status',
                  value: toSync ? '' : 'ignore',
                },
              ],
            },
          })
        )
      ).then(() => {
        queryClient.invalidateQueries('einsteinDocuments')
        queryClient.invalidateQueries('einsteinDocumentsExternal')
        onCancelRowEditMode()
        toggleAllRowsSelected(false)
        closeModal()
      })
    }
  }

  return (
    <Box m={4} width="580px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex mb={4} justifyContent="space-between" alignItems="center">
          <H2>{t('Edit Document Settings')}</H2>
          <Box cursor="pointer" onClick={closeModal}>
            <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
          </Box>
        </Flex>
        <Box>
          <Text fontSize="16px" fontWeight="600">
            Document Settings
          </Text>
        </Box>

        <Box>
          {showServerError && (
            <ErrorBanner
              mt={2}
              text={t(
                'An error has occurred. Please check your input and try submitting again.'
              )}
            />
          )}
          <Flex mt={16} mb={16} mx={-1} flexDirection={['column', 'row']}>
            <Box flex={[1, 1]} mt={[1, 0]} ml={1} mr={1}>
              <Label>{t('Status')}</Label>
              <Controller
                name="status"
                control={control}
                rules={{ required: false }}
                render={() => (
                  <BotSelect
                    options={statusOpts}
                    onChange={(selected) =>
                      setToSync(selected.value === 'synced')
                    }
                    value={toSync ? statusOpts[0] : statusOpts[1]}
                    isMulti={false}
                    isClearable={false}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="status"
                message={t('Status required.')}
                render={({ message }) => (
                  <Text color="danger" fontSize={12} mt={1} ml={1}>
                    {t(message)}
                  </Text>
                )}
              />
            </Box>
          </Flex>

          <Controller
            name="permissions"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <PermissionsField
                onChange={(newAclDnf) => {
                  setAclDnf(newAclDnf)
                }}
                aclDnf={aclDnf}
                setSelectedDepartmentForm={setSelectedDepartmentForm}
                setSelectedDesignationForm={setSelectedDesignationForm}
                orgKey={currentAdmin.hospital.organizationKey}
                {...field}
                // ... other props you need to pass to PermissionsField
                onValidation={handleValidation}
              />
            )}
          />
          {errors.permissions && (
            <Text color="danger" fontSize={12} mt={1} ml={1}>
              {errors.permissions.message}
            </Text>
          )}

          <Flex mt={7} justifyContent="flex-end">
            <SecondaryOutlinedButton
              type="button"
              borderRadius="8px"
              onClick={closeModal}
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <PrimaryButton
              borderRadius="8px"
              ml={3}
              type="submit"
              disabled={!isValid}
            >
              {t('Apply settings')}
            </PrimaryButton>
          </Flex>
        </Box>
      </form>
    </Box>
  )
}

export default DocumentBulkEditModal
