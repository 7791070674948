import React, { useEffect, useRef, useState, useCallback, memo } from 'react'
import { get, isNull, chain, map } from 'lodash'
import { useDropzone } from 'react-dropzone'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'

import { Box, Text, Flex, InputField, useApi, FAIcon } from '@fivehealth/botero'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import Form from 'components/Form/Form'
import { useOrganizationsContext } from 'context/OrganizationsContext'
import { checkLinkType } from './Utils'

const checkFileSize = (size, limit = 5) =>
  chain(size)
    .divide(1024) // KB
    .divide(1024) // MB
    .inRange(limit) // default 5MB
    .value()

const BroadcastMessageTemplateMedia = ({
  id,
  templateRef,
  defaultFormData,
  onFormChange,
  tmpId,
  ...props
}) => {
  const formRef = useRef([])
  const [updateTimeout, setUpdateTimeout] = useState(null)
  const [uploadFile, setUploadFile] = useState(null)
  const [uploadedData, setUploadedData] = useState({ uploadID: '' })
  const [uploadedUrl, setUploadedUrl] = useState('')
  const [uploadNewFile, setUploadNewFile] = useState(false)

  const {
    queries: { useStitchUpload },
  } = useApi({
    queries: ['useStitchUpload'],
  })

  const { isPublicHospital } = useOrganizationsContext()

  const { mutateAsync: uploadFileToStitch } = useStitchUpload({
    variables: {},
  })

  const handleFormChange = () => {
    if (updateTimeout) {
      clearTimeout(updateTimeout)
    }

    setUpdateTimeout(
      setTimeout(() => {
        onFormChange()
      }, 400)
    )
  }

  useEffect(() => {
    handleFormChange()
  }, [uploadedUrl])

  useEffect(() => {
    if (uploadFile) {
      const reader = new FileReader()
      reader.readAsDataURL(uploadFile)
      reader.onload = () => {
        setUploadedUrl(reader.result)
      }
    }
  }, [uploadedData])

  useEffect(() => {
    if (uploadFile && uploadFile !== null) {
      uploadFileToStitch({
        input: {
          key: 'einstein',
          mimeType: uploadFile.type,
        },
      }).then(({ stitchCreateUploadUrl }) => {
        const uploadUrl = stitchCreateUploadUrl.url
          ? stitchCreateUploadUrl.url
          : ''
        const uploadKey =
          stitchCreateUploadUrl.fields && stitchCreateUploadUrl.fields.key
            ? stitchCreateUploadUrl.fields.key
            : ''
        const uploadUrlWithKey = `${uploadUrl}${uploadKey}`
        const body = new FormData()
        const uploadedFile = uploadFile
        setUploadedData({
          ...uploadedData,
          urlWithKey: uploadUrlWithKey,
          uploadID: stitchCreateUploadUrl.uploadId,
        })
        map(stitchCreateUploadUrl.fields, (value, key) => {
          body.append(key, value)
        })
        body.append('file', uploadedFile)

        return fetch(stitchCreateUploadUrl.url, {
          method: 'post',
          body,
        })
      })
    }
  }, [uploadFile])

  const [fileTemplateForms, setFileTemplateForms] = useState([
    {
      id: 'file_source',
      subTitle: '',
      type: 'options',
      fields: [
        {
          id: 'file_source',
          type: 'options',
          label: 'Add video or audio file',
          options: [
            ...(!isPublicHospital
              ? [{ id: 'uploadFromDevice', label: 'Upload from device' }]
              : []),
            { id: 'mediaLink', label: 'Enter media URL' },
          ],
          required: true,
        },
      ],
    },
  ])

  const acceptedFileTypes = ['audio/mpeg', 'video/mp4']

  const resetMediaData = () => {
    setUploadFile(null)
    setUploadedData({ uploadID: '' })
    setUploadedUrl('')
  }

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length >= 1) {
      if (!acceptedFileTypes.includes(acceptedFiles[0].type)) {
        formRef.current[0].updateFormData(
          'uploadFromDeviceError',
          'File type not allowed. Only .mp4 and .mp3 files are allowed for media'
        )
        resetMediaData()
      } else if (!checkFileSize(acceptedFiles[0].size, 10)) {
        formRef.current[0].updateFormData(
          'uploadFromDeviceError',
          'Maximum of 10mb only is allowed for media'
        )
        resetMediaData()
      } else {
        setUploadFile(acceptedFiles[0])
        formRef.current[0].updateFormData('uploadFromDeviceError', '')
      }
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const style = {
    display: 'block',
    resize: 'none',
    height: '42px',
    padding: '9px',
  }

  const defaultUrlUpload =
    ((defaultFormData || {}).template || {}).default_url_upload || ''

  if (uploadNewFile && defaultUrlUpload) {
    // eslint-disable-next-line no-param-reassign
    delete defaultFormData.template.default_url_upload
  }

  const onSetTemplateFromRef = (ref) => {
    formRef.current[0] = ref
    let newTemplateForms = []
    const { uploadFromDeviceError } = formRef.current[0].formData

    if (uploadFromDeviceError) {
      style.border = '1px solid rgb(224, 81, 56)'
    }

    if (formRef.current[0].formData.file_source === 'uploadFromDevice') {
      newTemplateForms = [
        {
          id: 'file_source',
          subTitle: '',
          type: 'options',
          fields: [
            {
              id: 'file_source',
              type: 'options',
              label: 'Add video or audio file',
              options: [
                ...(!isPublicHospital
                  ? [{ id: 'uploadFromDevice', label: 'Upload from device' }]
                  : []),
                { id: 'mediaLink', label: 'Enter media URL' },
              ],
              required: true,
            },
          ],
        },
        {
          id: 'file_upload',
          noMarginBottom: true,
          fields: [
            {
              id: 'file_upload',
              type: 'custom',
              label: 'Upload file',
              placeholder: 'Input URL here',
              visibility: true,
              component: (
                <Box mb={2} mt={0} width="100%" {...getRootProps()}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection="row"
                  >
                    <Text
                      mb={1}
                      fontSize="12px"
                      fontWeight="bold"
                      color="darkestShade"
                    >
                      Upload video or audio file
                    </Text>
                    <Text mb={1} fontSize="12px" color="darkShade">
                      Allowed file types: mp3, mp4. Max 10MB
                    </Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center">
                    {defaultUrlUpload && !uploadNewFile ? (
                      <>
                        <InputField
                          mt="4px"
                          width="100%"
                          disabled
                          value={defaultUrlUpload}
                          style={style}
                        />
                        <Box ml={3} mr={1}>
                          <FAIcon
                            icon={faTimes}
                            fontSize={16}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setUploadNewFile(true)
                            }}
                          />
                        </Box>
                      </>
                    ) : (
                      <Flex
                        flexDirection="column"
                        justifyContent="flex-start"
                        width="100%"
                      >
                        <InputField
                          type="file"
                          width="100%"
                          height="100px"
                          {...getInputProps({ style })}
                          accept=".mp3, .mp4"
                        />
                        {!!formRef.current[0].formData
                          .uploadFromDeviceError && (
                          <ErrorMessage
                            error={
                              formRef.current[0].formData.uploadFromDeviceError
                            }
                          />
                        )}
                      </Flex>
                    )}
                  </Flex>
                </Box>
              ),
            },
          ],
        },
        {
          id: 'title',
          fields: [
            {
              id: 'title',
              type: 'input',
              label: 'File title',
              placeholder: 'Enter title of video or audio file',
              required: true,
              limit: 24,
              visibility: true,
            },
          ],
        },
      ]
    }

    if (formRef.current[0].formData.file_source === 'mediaLink') {
      newTemplateForms = [
        {
          id: 'file_source',
          subTitle: '',
          type: 'options',
          fields: [
            {
              id: 'file_source',
              type: 'options',
              label: 'Add video or audio file',
              options: [
                ...(!isPublicHospital
                  ? [{ id: 'uploadFromDevice', label: 'Upload from device' }]
                  : []),
                { id: 'mediaLink', label: 'Enter media URL' },
              ],
              required: true,
            },
          ],
        },
        {
          id: 'mediaUrl',
          fields: [
            {
              id: 'mediaUrl',
              type: 'input',
              label: 'Enter audio or video file URL',
              placeholder: 'https://www.youtube.com/xxxx',
              required: true,
              visibility: true,
            },
          ],
        },
        {
          id: 'title',
          fields: [
            {
              id: 'title',
              type: 'input',
              label: 'File title',
              placeholder: 'Enter title of video or audio file',
              required: true,
              limit: 24,
              visibility: true,
            },
          ],
        },
      ]
    }

    if (newTemplateForms.length) {
      setFileTemplateForms(newTemplateForms)
    }

    return true
  }

  const resetFormData = () => {
    formRef.current[0].resetFormData()
  }

  const getTemplateData = (shouldValidate) => {
    let hasError = false

    // append audio and video files here
    const templateFormData = formRef.current[0].getFormData(
      shouldValidate,
      null,
      fileTemplateForms
    )

    if (
      formRef.current[0].formData.file_source === 'uploadFromDevice' &&
      shouldValidate &&
      !uploadedUrl.length &&
      !defaultUrlUpload
    ) {
      hasError = true
      formRef.current[0].updateFormData(
        'uploadFromDeviceError',
        'Upload file is required.'
      )
    }

    if (!isNull(templateFormData) && !hasError) {
      if (
        defaultUrlUpload &&
        templateFormData.file_source === 'uploadFromDevice'
      ) {
        return {
          type: 'message',
          message: {
            type: 'carousel',
            carousel: [
              {
                type: 'sections',
                sections: [
                  {
                    type: templateFormData.type || 'video',
                    [templateFormData.type || 'video']: {
                      url: defaultUrlUpload,
                      additionalProperties: {
                        file_source: 'uploadFromDevice',
                      },
                    },
                  },
                  {
                    type: 'paragraphs',
                    paragraphs: [
                      {
                        type: 'title',
                        title: templateFormData.title,
                      },
                    ],
                  },
                  {
                    type: 'actions',
                    actions: [
                      {
                        type: 'button',
                        button: {
                          url: defaultUrlUpload,
                          content_type: templateFormData.type || 'video',
                          text: `Play ${templateFormData.type || 'video'}`,
                          style: 'link',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        }
      }

      if (
        templateFormData.file_source === 'mediaLink' &&
        !!templateFormData.mediaUrl
      ) {
        const linkType = checkLinkType(templateFormData.mediaUrl.trim())
        return {
          type: 'message',
          message: {
            type: 'carousel',
            carousel: [
              {
                type: 'sections',
                sections: [
                  {
                    type: linkType,
                    [linkType]: {
                      url: templateFormData.mediaUrl.trim(),
                      additionalProperties: {
                        file_source: 'mediaLink',
                      },
                    },
                  },
                  {
                    type: 'paragraphs',
                    paragraphs: [
                      {
                        type: 'title',
                        title: templateFormData.title,
                      },
                    ],
                  },
                  {
                    type: 'actions',
                    actions: [
                      {
                        type: 'button',
                        button: {
                          url: templateFormData.mediaUrl.trim(),
                          content_type: linkType,
                          text: `Play ${linkType}`,
                          style: 'link',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        }
      }

      const urlUpload = uploadedData.uploadID ? uploadedData.uploadID : ''
      let url = uploadedData.uploadID ? `stitch://${uploadedData.uploadID}` : ''

      if (!shouldValidate) {
        url = uploadedUrl || ''
      }

      const linkType = checkLinkType(urlUpload)

      return {
        type: 'message',
        message: {
          type: 'carousel',
          carousel: [
            {
              type: 'sections',
              sections: [
                {
                  type: linkType,
                  [linkType]: {
                    url,
                    additionalProperties: {
                      file_source: templateFormData.file_source,
                    },
                  },
                },
                {
                  type: 'paragraphs',
                  paragraphs: [
                    {
                      type: 'title',
                      title: templateFormData.title,
                    },
                  ],
                },
                {
                  type: 'actions',
                  actions: [
                    {
                      type: 'button',
                      button: {
                        url,
                        content_type: linkType,
                        text: `Play ${linkType}`,
                        style: 'link',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      }
    }

    return null
  }

  const getRawFormData = () => ({
    tmpId,
    template: formRef.current[0].formData,
    selected: 'media',
    title: formRef.current[0].formData.title
      ? formRef.current[0].formData.title
      : '',
    url_upload: uploadedUrl || '',
  })

  useEffect(() => {
    if (templateRef) {
      templateRef({ getTemplateData, getRawFormData, resetFormData })
    }
  }, [fileTemplateForms, uploadedUrl])

  return (
    <div data-testid="media-form-container">
      <Box>
        <Form
          key={`${id}-${uploadNewFile}`}
          onFormChange={handleFormChange}
          formRef={onSetTemplateFromRef}
          forms={fileTemplateForms}
          formSpacing={false}
          defaultFormData={get(defaultFormData, 'template')}
          labelSize="12px"
          withinOutlinedBox
          {...props}
        />
      </Box>
    </div>
  )
}

export default memo(
  BroadcastMessageTemplateMedia,
  ({ tmpId: oldTmpId }, { tmpId: newTmpId }) => oldTmpId === newTmpId
)
