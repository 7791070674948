import React, { useState, useCallback, useEffect } from 'react'
import {
  Box,
  FAIcon,
  Flex,
  Text,
  useApi,
  DataTable,
  InputField,
} from '@fivehealth/botero'
import { format, parseISO } from 'date-fns'
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { isEqual, isEmpty, startCase, trim, chain } from 'lodash'
import { DateTime } from 'luxon'
import BotmdCryBaby from 'assets/bot-crying-avatar.svg'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import { useModal } from 'context/ModalContext'
import BasicDeleteModal from 'components/Modals/BasicDeleteModal'
import AddDepartmentDesignationModal from 'components/Modals/AddDepartmentDesignationModal'
import BasicEditModal from '../../components/Modals/BasicEditModal'
import { AlertModalConfirmation } from './helper'
import EventsConstant from '../../config/constants/events.constants'
import { EXPORT_GQL_DOC as EXPORT_DIVISION } from '../../api/queries/useDivisions'

const getSortId = (columnId) => {
  switch (columnId) {
    case 'createdOn':
      return 'CREATED_ON'
    case 'name':
      return 'NAME'
    case 'createdBy':
      return 'CREATED_BY'
    default:
      return columnId
  }
}

const DivisionTable = ({
  invalidateSettings,
  partialEditModalProps,
  partialDeleteModalProps,
  setExportOptions,
  exportFileUsingLink,
  setAddOptions,
  setDepSearchQuery,
  setSelectedTabCount,
  tab,
}) => {
  const INIT_DEFAULT_FETCH = 25
  const DATE_FORMAT = 'dd MMMM yyyy, h:mm a'
  const DIVISION = 'division'
  const { client } = useApi()
  const [searchQuery, setSearchQuery] = useState('')
  const [allDivisionData, setAllDivisionData] = useState([])
  const [sortParams, setSortParams] = useState({
    id: 'createdOn',
    desc: true,
  })
  const { openModal, closeModal } = useModal()
  const {
    queries: { useDivisions },
  } = useApi({
    queries: ['useDivisions'],
  })

  const getExportDivisionsURL = async (outputFormat) => {
    try {
      const res = await client.request(EXPORT_DIVISION, {
        outputFormat,
        search: searchQuery,
      })
      return res?.hospitalDivisions?.exportByUrl
    } catch (e) {
      return false
    }
  }
  const ExportOptions = {
    value: DIVISION,
    label: 'All divisions',
    query: getExportDivisionsURL,
    handleExportData: async (fileType) => {
      const exportUrl = await ExportOptions?.query(fileType)
      const fileName = `WorkspaceSetting${ExportOptions?.label?.replaceAll(
        ' ',
        ''
      )}`
      exportFileUsingLink(exportUrl, fileName)
    },
    buttonLable: 'Export Data',
  }

  const { mutateAsync: createDivisionMutation } = useDivisions().create({
    variables: {},
    onSuccess: (response) => {
      openModal(
        <AlertModalConfirmation
          logEventProps={{
            subSource: EventsConstant.WORKSPACE_SETTINGS_LIST,
            eventName: EventsConstant.DIVISION_CREATED_SUCCESS,
            page: EventsConstant.WORKSPACE_SETTINGS_PAGE,
          }}
          title="Division has been created"
          description={format(
            parseISO(
              response?.data?.hospitalDivisionCreate?.hospitalDivision
                ?.createdOn
            ),
            DATE_FORMAT
          )}
          closeModal={() => {
            closeModal()
            invalidateSettings(DIVISION)
          }}
        />
      )
    },
  })

  useEffect(() => {
    setExportOptions(null)
    setAddOptions(null)
    setExportOptions(ExportOptions)
    setAddOptions({
      func: () =>
        openModal(
          <AddDepartmentDesignationModal
            type="division"
            handleClose={closeModal}
            createDivisiontMutation={createDivisionMutation}
          />
        ),
      label: 'Add Division',
    })
  }, [])
  const {
    data: dataDivision,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingDepts,
  } = useDivisions().paginated({
    variables: {
      sortBy: getSortId(sortParams.id),
      sortDesc: sortParams.desc,
      first: INIT_DEFAULT_FETCH,
      search: searchQuery,
      outputFormat: 'CSV',
    },
  })
  useEffect(() => {
    const tempTasks = chain(dataDivision)
      .get('pages', [])
      .flatMap((page) => page || [])
      .map((task) => task)
      .value()
    setAllDivisionData(tempTasks)
  }, [dataDivision])
  useEffect(() => {
    setSelectedTabCount({
      isFetching: isLoadingDepts,
      count: dataDivision?.totalCount,
      tab,
    })
  }, [dataDivision, fetchNextPage])

  const { mutateAsync: updateDivisionMutation } = useDivisions().update({
    variables: {},
    onSuccess: ({ data }) => {
      let isError = false
      let modalProps = null
      if (data?.errors?.length > 0) {
        modalProps = {
          title: 'Error in renaming division',
          description: data.errors[0]?.message.includes('Invalid input.')
            ? 'Invalid input.'
            : 'Division with same name already exists. Please rename and try again.',
          botmdImage: BotmdCryBaby,
        }
        isError = true
      } else {
        modalProps = {
          title: 'Edits have been saved',
          description: format(
            parseISO(data?.hospitalDivisionUpdate?.hospitalDivision.updatedOn),
            DATE_FORMAT
          ),
        }
      }

      openModal(
        <AlertModalConfirmation
          logEventProps={{
            subSource: EventsConstant.WORKSPACE_SETTINGS_LIST,
            eventName: isError
              ? EventsConstant.DIVISION_RENAMED_FAILED
              : EventsConstant.DIVISION_RENAMED_SUCCESS,
            page: EventsConstant.WORKSPACE_SETTINGS_PAGE,
          }}
          closeModal={() => {
            closeModal()
            invalidateSettings(DIVISION)
          }}
          {...modalProps}
        />
      )
    },
  })

  const { mutateAsync: deleteDivisionMutation } = useDivisions().delete({
    variables: {},
    onSuccess: () => {
      openModal(
        <AlertModalConfirmation
          logEventProps={{
            subSource: EventsConstant.WORKSPACE_SETTINGS_LIST,
            eventName: EventsConstant.DIVISION_DELETED_SUCCESS,
            page: EventsConstant.WORKSPACE_SETTINGS_PAGE,
          }}
          title="Division successfully deleted"
          description={format(new Date(), DATE_FORMAT)}
          closeModal={() => {
            closeModal()
            invalidateSettings(DIVISION)
          }}
        />
      )
    },
  })

  const { mutateAsync: createDivisionMutationNoModal } = useDivisions().create({
    variables: {},
    onSuccess: (response) => {
      openModal(
        <AlertModalConfirmation
          logEventProps={{
            subSource: EventsConstant.WORKSPACE_SETTINGS_LIST,
            eventName: EventsConstant.DESIGNATION_CREATED_SUCCESS,
            page: EventsConstant.WORKSPACE_SETTINGS_PAGE,
          }}
          title="Designation has been created"
          description={format(
            parseISO(
              response?.data?.hospitalDesignationCreate?.hospitalDivision
                ?.createdOn
            ),
            DATE_FORMAT
          )}
          closeModal={() => {
            closeModal()
            invalidateSettings(DIVISION)
          }}
        />
      )
    },
  })

  const onDivisionDeleteHandler = async ({
    uid,
    reassignName,
    createNewTitle,
  }) => {
    let newDivisionName = null
    if (createNewTitle) {
      const createResponse = await createDivisionMutationNoModal({
        create: { name: trim(createNewTitle) },
      })
      newDivisionName =
        createResponse?.hospitalDivisionCreate?.hospitalDivision?.name
    }
    if (newDivisionName || reassignName) {
      const replaceName = reassignName || newDivisionName
      deleteDivisionMutation({
        delete: {
          uid,
          replaceName,
        },
      })
    } else {
      deleteDivisionMutation({
        delete: {
          uid,
        },
      })
    }
  }

  const BroadcastManagerDraftRowEdit = ({
    data,
    showTaskInfo,
    dropdownRef,
  }) => {
    const editAction = [
      {
        id: 'editDivisionName',
        label: 'Edit',
        showTaskInfo,
        onClick: () =>
          openModal(
            <BasicEditModal
              onSubmit={({ uid, updatedValue }) => {
                updateDivisionMutation({
                  update: {
                    uid,
                    ...updatedValue,
                  },
                })
              }}
              data={data}
              {...partialEditModalProps(DIVISION)}
              type="division"
            />
          ),
      },
      {
        id: 'deleteDivision',
        label: 'Delete',
        showTaskInfo,
        color: 'red',
        divider: true,
        onClick: () =>
          openModal(
            <BasicDeleteModal
              data={data}
              onSubmit={({ uid, reassignName, createNewTitle }) => {
                onDivisionDeleteHandler({
                  uid,
                  reassignName,
                  createNewTitle,
                })
              }}
              {...partialDeleteModalProps(DIVISION, data)}
            />
          ),
      },
    ]

    return (
      <DropdownMenu
        fontWeight="normal"
        actions={editAction}
        dropdownRef={dropdownRef}
      />
    )
  }

  const createColumns = useCallback((divisionData) => {
    const division = divisionData
    if (!isEmpty(division)) {
      const result = ['name', 'createdOn', 'createdBy'].map((key) => ({
        id: key,
        accessor: key === 'createdBy' ? 'createdBy.firstName' : key,
        Header: startCase(key),
        disableSortBy: false,
        Cell: (data) => {
          if (isEqual(key, 'createdOn') || isEqual(key, 'updatedOn')) {
            return (
              <Text>
                {data.value
                  ? DateTime.fromISO(data.value)
                      .setLocale('en-SG')
                      .toFormat('dd LLL yyyy, t')
                  : '-'}
              </Text>
            )
          }
          return <Text>{data.value}</Text>
        },
      }))
      return result
    }
    return []
  }, [])

  return (
    <Box mb={2} mt={3}>
      <Flex
        mb={2}
        pr={2}
        pl={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box flex={[0.95, null]} mb={1}>
            <InputField
              data-testid="divisionSearchId"
              justifyContent="space-between"
              placeholder="Search by division name"
              startIcon={
                <FAIcon icon={faSearch} fontSize={16} color="fullShade" />
              }
              width={['100%', 500]}
              fontWeight="400"
              height="43.5px"
              lineHeigt="24px"
              fontSize={[14]}
              endIcon={
                !isEmpty(searchQuery) && (
                  <FAIcon
                    cursor="pointer"
                    onClick={() => {
                      setSearchQuery('')
                      setDepSearchQuery('')
                    }}
                    icon={faTimes}
                    fontSize={16}
                    color="fullShade"
                  />
                )
              }
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value)
                setDepSearchQuery(e.target.value)
              }}
            />
          </Box>
        </Flex>
      </Flex>
      {isLoadingDepts || !allDivisionData ? (
        <ProgressAnimation />
      ) : (
        <div>
          <DataTable
            columns={createColumns(allDivisionData)}
            data={allDivisionData}
            onFetchData={({ sortBy }) => {
              setSortParams(sortBy[0])
            }}
            initialSortBy={sortParams}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            enableRowEdit="true"
            onFetchNextPage={fetchNextPage}
            renderRowEdit={({ original }) => (
              <BroadcastManagerDraftRowEdit
                data={original}
                onDeleteBroadcast={() => {}}
                showTaskCreate={() => {}}
                showTaskInfo={() => {}}
                dropdownRef={() => {}}
              />
            )}
          />
        </div>
      )}
    </Box>
  )
}

export default DivisionTable
