import React from 'react'
import { isEmpty } from 'lodash'
import { InputField, FAIcon } from '@fivehealth/botero'
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'

const InputSearchField = ({
  testId,
  placeHolder,
  searchKeyword,
  setSearchKeyword,
  onKeyDownAction,
  ...props
}) => (
  <InputField
    disabled={false}
    data-testid={testId}
    justifyContent="space-between"
    alignItems="center"
    placeholder={placeHolder}
    startIcon={<FAIcon icon={faSearch} fontSize={16} color="fullShade" />}
    fontWeight="400"
    height="43.5px"
    lineHeigt="24px"
    fontSize={[12, 14]}
    width="100%"
    endIcon={
      !isEmpty(searchKeyword) && (
        <FAIcon
          cursor="pointer"
          onClick={() => {
            setSearchKeyword('')
          }}
          icon={faTimes}
          fontSize={16}
          color="fullShade"
        />
      )
    }
    value={searchKeyword}
    onChange={(e) => setSearchKeyword(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        onKeyDownAction()
      }
    }}
    maxLength={60}
    {...props}
  />
)

export default InputSearchField
